import React, {useContext, useState} from "react"
import {useTranslation} from "next-i18next"
import {useRouter} from "next/router"
import NavItem from "./NavItem"
import {filterUrl} from "../../filter/FilterUrl"
import urlParams from "../../../utility/UrlParams"
import AccordionAnimation from "../../reusable/components/AnimationComponent/AccordionAnimation"
import NewMegaMenu from "./NewMegaMenu"
import Image from "next/image"
import DataContext from "../../../context/DataContext"
import {formatStrapiData} from "../../../strapi/strapiHelpers"
import {CollectionName} from "../../../constants"

type Props = {
  dropdownData: any
  closeMegaMenu: any
  isToggleOpen: boolean
  setIsToggleOpen: any
}

const DesktopBottomHeader: React.FC<Props> = ({
	isToggleOpen,
	setIsToggleOpen
}) => {
	const {pageProps: {strapiContent}, brandWorld} = useContext(DataContext)
	// eslint-disable-next-line
	const megaMenuData = strapiContent?.data?.mega_menus
	const strapiMegaMenuData = formatStrapiData(megaMenuData, ["title", "slugName", "link", "buttonTitle"])
	const {t} = useTranslation("common")
	const router = useRouter()
	const isBrandWorld = router.pathname.includes("/brand-world")
	const [activeItem, setActiveItem] = useState(null)
	const saleTitleSelection = "Sale"
	const handleMouseEnter = itemSlugName => {
		setIsToggleOpen(true)
		setActiveItem(itemSlugName)
	}

	const handleMouseLeave = () => {
		setIsToggleOpen(true)
		setActiveItem(null)
	}

	return (
		<div
			style={{minWidth: 400, marginRight: "auto", height: 48}}
			onMouseLeave={handleMouseLeave}
		>
			<ul
				className="d-flex list-unstyled align-items-start justify-content-center mb-0"
				style={{height: "100%", minWidth: 400}}
			>
				{strapiMegaMenuData?.map((item, index) => (
					<div
						key={index}
						className="position-relative pointer col-auto p-0  text-bold"
						onMouseEnter={() => handleMouseEnter(item.slugName)}
						style={{height: "100%"}}
					>
						<NavItem
							className="d-flex align-items-start"
							action={handleMouseEnter.bind(null, item.slugName)}
							label={item?.slugName === "sale" ? saleTitleSelection : t(item.title)}
							href={
								item?.slugName === "all-models" ? filterUrl({}, null, null) :
									item?.slugName === "sale" ?
										"/alle-modelle-e-bike-schweiz?categories=sale_" :
										isBrandWorld ? `${filterUrl({[urlParams.categories]: item.link}, urlParams.categories, item.link, CollectionName.BrandWorld, CollectionName.BrandWorld, brandWorld.brandSlug)}#products-listing` :
											filterUrl({[urlParams.categories]: item.link}, null, null)}
							classGroup="nav-link-black text-secondary position-relative"
							isNoUppercase
							typographyVariant="bodySm"
							itemTitle={item.title}
							activeItem={activeItem}
							hasNoMargin
							isLink={item?.slugName !== "all-brands"}
							noChevron={item?.slugName === "45-km" || item?.slugName === "sale"}
							icon={item?.slugName === "sale" && <Image src="/assets/icons/fire.svg"
								height={20}
								width={20}/>}
							iconFirst={false}
						/>
						<div
							style={{left: index < 3 ? 0 : index > 4 ? -130 : 0, zIndex: 90000}}
							className="overflow-hidden navbar-absolute">
							<AccordionAnimation
								inProp={isToggleOpen && item.slugName === activeItem &&
                                    (item.slugName !== "45-km" && item?.slugName !== "sale")}
								duration={200}
								transTimingFn="ease-out"
								unmount>
								<NewMegaMenu
									toggleMenu={handleMouseLeave}
									item={item}
									mainCategory={activeItem}
								/>
							</AccordionAnimation>
						</div>
					</div>
				))}
			</ul>
		</div>
	)
}

export default DesktopBottomHeader

import React, {useEffect, useState} from "react"
import Modal from "../reusable/components/Modal/Modal"
import styles from "./WheelOfFortuneModal.module.scss"
import Image from "next/image"
import Typography from "../reusable/Typography/Typography"
import WheelOfFortuneForm from "./WheelOfFortuneForm"
import {useTranslation} from "next-i18next"
import confetti from "canvas-confetti"
import {startConfettiAnimation} from "../reusable/StartConfettiAnimation"
import Countdown from "../countdown/Countdown"
import WheelDecoration from "./WheelDecoration"

interface Props {
    children?: React.ReactNode;
    selectedOption?: any;
    isSpinComplete?: boolean;
    formId?: string;
    modalContentType?: "wheel" | "form";
    onClose?: () => void;
    isChristmasWheel?: boolean;
    isNewYearWheel?: boolean;
    theme?: "default" | "christmas" | "newyear" | "love" | "spring";
    isLoveWheel?: boolean
    isSpringWheel?: boolean
}

const themeTitles: Record<string, string> = {
	christmas: "spin-christmas-wheel-title",
	newyear: "spin-newyear-wheel-title",
	love: "spin-love-wheel-title",
	spring: "spin-spring-wheel-title"
}

const WheelOfFortuneModal: React.FC<Props> = ({
	children,
	selectedOption,
	isSpinComplete,
	formId,
	modalContentType,
	onClose,
	isChristmasWheel,
	isNewYearWheel,
	theme,
	isLoveWheel,
	isSpringWheel
}) => {
	const [isModalOpen, setIsModalOpen] = useState(true)
	const [confettiShown, setConfettiShown] = useState(false)
	const {t} = useTranslation()

	const handleModalClose = () => {
		if (onClose) {
			onClose()
		}

		setIsModalOpen(false)

		confetti.reset?.()
	}

	useEffect(() => {
		if ((isSpinComplete || (isChristmasWheel || isNewYearWheel)) && !confettiShown) {
			startConfettiAnimation(isChristmasWheel ? "snowflake" :
				isNewYearWheel ? "fireworks" : isLoveWheel ? "hearts" : null)
			setConfettiShown(true)
		}
	}, [isSpinComplete, isSpringWheel, confettiShown])

	const modalTitle = t(themeTitles[theme] || "spin-wheel-title")

	return (
		<Modal isOpen={isModalOpen}
			closeModal={handleModalClose}>
			<WheelDecoration theme={theme}/>
			<div className={`${styles["modal-container"]} mt-5 px-3 px-md-0 ${theme === "spring" ? "mt-md-4" : "mt-md-0"} `}>
				<div className={styles["modal-header"]}>
					<div onClick={handleModalClose}
						className="d-flex justify-content-end cursor-pointer">
						<Image src="/assets/icons/close-icon-white.svg"
							width={16}
							height={16} />
					</div>
					{modalContentType === "form" || isSpinComplete ? null : (
						<>
							<Typography variant="heading1Black"
								className="text-white mt-2 mb-3 text-center">
								{modalTitle}
							</Typography>
							<Typography variant="bodySm"
								className="text-white text-center mb-4">
								{t("spin-wheel-desc")}
							</Typography>
						</>
					)}
				</div>
				{modalContentType === "form" || isSpinComplete ? (
					<WheelOfFortuneForm
						selectedOption={selectedOption}
						formId={formId}
						onClose={handleModalClose}
						theme={theme}
					/>
				) : (
					children
				)}
				{modalContentType === "form" || isSpinComplete ? null : (
					<div className={styles["modal-footer"]}>
						<Typography variant="bodySm"
							className="text-white text-center mt-4">
							{t("spin-wheel-footer-text")}
						</Typography>
						<div className={"mt-2 mb-2"}>
							<Countdown/>
						</div>
						<Typography variant="bodySm"
							className="text-white text-center mt-4">
							{t("only-on-centain-months")}
						</Typography>
					</div>
				)}
			</div>
			{isChristmasWheel ?
				<div className={`${styles["ice-overlay-bottom"]} position-absolute start-0`}
					style={{bottom: -35, width: "100%"}}>
					<Image src="/assets/icons/ice-overlay-bottom-desktop.svg"
						width={"100%"}
						height={200}
						layout="fill"
						style={{top: 0}}
					/>
				</div> : null
			}
		</Modal>
	)
}

export default WheelOfFortuneModal

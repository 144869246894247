import React, {useContext, useEffect} from "react"
import {useTranslation} from "next-i18next"
import Typography from "../Typography/Typography"
import {X} from "react-bootstrap-icons"
import Image from "next/image"
import FormNewsletter from "../../forms/FormNewsletter"
import {Color} from "../FormInput/FormInput"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import AnalyticsContext from "../../../context/AnalyticsContext"

type Props = {closeModal: ()=>void}

const NewsletterModalContent: React.FC<Props> = ({closeModal}) => {
	const {t} = useTranslation()
	const {reactGA} = useContext(AnalyticsContext)

	useEffect(() => {
		reactGA?.event({
			category: gaCategories.pdp,
			action: gaEvents.newsletterPopUp,
			label: gaEvents.newsletterPopUp,
			nonInteraction: false
		})
	}, [])

	return (
		<div onClick={e => {
			e.stopPropagation()
		}}
		className="newsletter-modal container-md bg-white px-2 px-md-0 ">
			<div className={"row m-0 justify-content-end d-md-none"}>
				<div className={"col-auto px-0 px-md-3"}>
					<div className={"py-2 px-0"}>
						<button type={"button"}
							className={"btn px-0"}
							onClick={closeModal}>
							<X size={30}
								color={"black"}/>
						</button>
					</div>
				</div>
			</div>
			<div className="row mx-1 d-md-none">
				<div style={{maxHeight: 100}}
					className="col-3 p-0 ps-2 rounded-3 overflow-hidden position-relative">
					<Image src={"/assets/images/blog/PortraitSarina.jpg"}
						objectFit="cover"
						layout="fill"/>
				</div>
				<div className="col-9">
					<Typography variant="bodyLgBold"
						semanticTag="h3"
						className="col-auto">{t("newsletter-title-about-3-months-free-insurance")}</Typography>
					<Typography variant="bodyLg"
						semanticTag="p"
						className="col-auto m-0">{t("newsletter-text-about-3-months-free-insurance")}</Typography>
				</div>
			</div>
			<div className="row position-relative mt-3 mt-md-3 mx-0 justify-content-between">
				<div className="col-12 col-md-6 bg-white py-md-5 d-flex justify-content-center align-items-center">
					<div className="container px-0 px-md-4 pb-5 pb-lg-0">
						<div className="d-md-none">
							<FormNewsletter fieldBgColor={Color.white}
								blogNewsletter
								buttonText={"register-now"}/>
						</div>

						<div className="row mb-md-4">

							<div className="row d-none d-md-flex">
								<Typography className="col-12 mb-2"
									variant="heading3"
									semanticTag="h2">{t("newsletter-title-about-3-months-free-insurance")}</Typography>
								<Typography className="col-12 mb-0"
									variant="bodyLg"
									semanticTag="h3">{t("newsletter-text-about-3-months-free-insurance")}</Typography>
							</div>

						</div>
						<div className="d-none d-md-flex">
							<FormNewsletter fieldBgColor={Color.white}
								blogNewsletter
								buttonText={"register-now"}/>
						</div>

					</div>
				</div>

				<div className="col-5 p-0 position-relative overflow-hidden d-none d-md-block">
					<Image src="/assets/images/sarina-popup-modal.jpg"
						alt="bike"
						layout="responsive"
						width={410}
						height={605}
						objectFit="contain"
						objectPosition="center"
					/>
					<button type={"button"}
						className={"btn bg-white rounded-circle p-1 m-2 position-absolute top-0 end-0 pointer"}
						onClick={closeModal}>
						<X size={20}
							color={"black"}/>
					</button>
				</div>
			</div>
		</div>
	)
}

export default NewsletterModalContent

import React, {useContext, useMemo} from "react"
import DesktopNavBar from "./desktop/DesktopNavBar"
import MobileNavBar from "./mobile/MobileNavBar"
import PropTypes from "prop-types"
import {getMenuTitles} from "../../utility/Helper"
import DataContext from "../../context/DataContext"
import {useRouter} from "next/router"
import StickyBar from "./StickyBar"
import StoriesSection from "../story/StoriesSection"
import useHandleNavbarScrollBehaviour from "../../hooks/useHandleNavbarScrollBehaviour"

export type MenuItem = {
	name: string
	href: string,
	src: string,
	imgDimensions: any
}

export type MenuTitle = {
	name: string
	items: MenuItem[]
}

type Props = {
	absolute?: boolean
	customLanguageSwitcher?:React.ReactNode
	stickyTop?: boolean
}

const NavBar = ({absolute = false, customLanguageSwitcher = null, stickyTop = true}: Props) => {
	const {collections, brands} = useContext(DataContext)
	const menuTitles = useMemo(() => getMenuTitles(collections, brands), [collections, brands])
	const router = useRouter()
	const isSimilarBikesModalOpen = router?.query?.modal === "open"
	const {isScrollingDown} = useHandleNavbarScrollBehaviour(isSimilarBikesModalOpen)
	return (
		<>
			<div className="d-md-none">
				<StickyBar style={{padding: "10px 12px"}} />
			</div>
			<DesktopNavBar hideNavBar={isScrollingDown && !isSimilarBikesModalOpen}
				menuTitles={menuTitles.desktop}
				absolute={absolute}
				customLanguageSwitcher={customLanguageSwitcher ? <>{customLanguageSwitcher}</> : null}/>
			<StoriesSection />
			<MobileNavBar menuTitles={menuTitles.mobile}
				absolute={absolute}
				stickyTop={stickyTop}
				isHidden={isScrollingDown} />
		</>
	)
}

NavBar.propTypes = {
	absolute: PropTypes.bool,
	stickyTop: PropTypes.bool,
	customLanguageSwitcher: PropTypes.node
}

export default NavBar

import React from "react"
import PropTypes from "prop-types"
import Typography, {Variants} from "../Typography/Typography"
import RichTextHyperlink from "./RichTextHyperlink"

type Props = {
  content: any,
  textAlign?: string,
  isMobile?: boolean,
  variant?: keyof typeof Variants
}

const RichTextUL = ({content, isMobile, textAlign, variant = "bodyLg"}: Props) => {
	return (
		<ul className="">
			{/* <Typography semanticTag="p" style={style} variant={variant} className={className}> */}
			{content.map(({content}) => {
				return	content.map(({content}) => {
					return content.map((item, idx) => {
						const {marks, value} = item
						const lines = (value || "").split("\n")
						const htmlValue = lines.join("<br/>")
						switch (item.nodeType) {
							case "text":
								return (
								// Marks.length === 0 ? <Typography key={idx} variant="bodySm" semanticTag="span" htmlContent={htmlValue}/> :

									marks.find(mark => mark?.type === "bold") ?
										<li className="ms-3">
											<Typography key={idx}
												semanticTag="span"
												variant={`${variant}Bold` as keyof typeof Variants}
												htmlContent={htmlValue}/>
										</li> :
										<li className="ms-3">
											<Typography key={idx}
												semanticTag={`${isMobile ? "p" : "span"}`}
												style={{textAlign}}
												variant={variant}
												htmlContent={htmlValue}/>
										</li>
								)
							case "hyperlink":
								return <RichTextHyperlink key={idx}
									item={item}/>
							default:
								return value
						}
					})
				})
			})
			}
			{/* </Typography> */}
		</ul>
	)
}

RichTextUL.propTypes = {
	content: PropTypes.any.isRequired

}

export default RichTextUL

// {content.map((item, idx) => {
// 	const {marks, value} = item
// 	const lines = (value || "").split("\n")
// 	const htmlValue = lines.join("<br/>")
// 	switch (item.nodeType) {
// 		case "list-item":
// 			return (
// 			// Marks.length === 0 ? <Typography key={idx} variant="bodySm" semanticTag="span" htmlContent={htmlValue}/> :

// 				marks && marks.find(mark => mark.type === "bold") ?
// 					<Typography key={idx} semanticTag="span" variant={`${variant}Bold` as keyof typeof Variants}
// 						htmlContent={htmlValue}/> :
// 					<Typography key={idx} semanticTag={`${isMobile ? "p" : "span"}`} style={{textAlign}} variant={variant}
// 						htmlContent={value}/>
// 			)
// 		case "hyperlink":
// 			return <RichTextHyperlink key={idx} item={item}/>
// 		default:
// 			return value
// 	}
// })
// }

import Image from "next/image"
import React, {useContext, useEffect, useState} from "react"
import {StoryItem} from "../../context/StoryContext"
import AnalyticsContext from "../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"

type Props = {
    story: StoryItem
	handleStoryOpen
	stories?:StoryItem[]
}
function StoryThumbnail({stories, story, handleStoryOpen}:Props) {
	const [isSeen, setIsSeen] = useState(false)
	const {reactGA} = useContext(AnalyticsContext)
	const handleIsSeen = () => {
		const storiesState = JSON.parse(localStorage.getItem("seenStories") || "{}")
		const currentStory = storiesState?.[story?.id]
		setIsSeen(Boolean(currentStory?.isSeen || false))
	}

	useEffect(() => {
		handleIsSeen()
		window.addEventListener("sessionStorage", () => handleIsSeen())

		return () => window.removeEventListener("sessionStorage", () => handleIsSeen())
	}, [story])

	return (
		<div onClick={() => {
			reactGA?.event({
				category: gaCategories.homePage,
				action: gaEvents.homepageStoryThumbnailClicked,
				label: story?.label || "story thumbnail clicked",
				nonInteraction: false
			})
			handleStoryOpen(story)
			setIsSeen(() => true)
		}}
		className={`story-thumnail ${isSeen ? "seen-borders" : "not-seen-borders"}`}>
			<Image style={{borderRadius: "80%", minWidth: "60px", minHeight: "60px", position: "relative"}}
				src={story.thumbnail || ""}
				objectFit="cover"
				width={60}
				height={60}
				layout="fixed" />

		</div>
	)
}

export default StoryThumbnail

import Link from "next/link"
import Typography from "../../reusable/Typography/Typography"
import NavDropdown from "./NavDropdown"
import React from "react"
import {MenuTitlesArray} from "../../../utility/Helper"
import {useTranslation} from "next-i18next"
import {useRouter} from "next/router"
import {freeServices} from "../../../constants"

export type serviceType = { title: (t: any) => string, description: (t: any) => string, imageUrl: string, handleClick: (paramsObject: any) => void }
export type serviceLabel = (t:any)=>string
type Props = {
  menuTitles: MenuTitlesArray[];
  dropdownData?: {
	label: string
	isFreeService?: boolean
    items: { itemLabel: string; href: string }[]
  }[];
  customLanguageSwitcher: React.ReactNode
  closeMegaMenu: any
};

const DesktopTopHeader: React.FC<Props> = ({
	customLanguageSwitcher,
	dropdownData,
	closeMegaMenu}) => {
	const {t} = useTranslation("common")
	const router = useRouter()

	return (
		<div className="top-navbar d-flex justify-content-between align-items-center"
			style={{height: "2.4rem", marginRight: "-1rem"}}>

			<div className="d-flex align-items-center justify-content-end"
				style={{maxWidth: 432}}>
				<ul className="d-flex list-unstyled align-items-center mb-0">
					<ul className="d-flex list-unstyled align-items-center position-relative">
						<NavDropdown
							action={closeMegaMenu}
							title={freeServices.label}
							services={freeServices.items}
							classGroup={" text-secondary"}
							isNoUppercase
							isFreeServices
							typographyVariant="bodySm"
							isChevronIcon
						/>
					</ul>
					<ul className="d-flex list-unstyled align-items-center position-relative">
						{dropdownData.map(({label, items}, index) => (
							<NavDropdown
								action={closeMegaMenu}
								key={index}
								label={t(label) }
								items={items}
								classGroup={" text-secondary"}
								isNoUppercase
								typographyVariant="bodySm"
								isChevronIcon
							/>
						))}
					</ul>

					<li
						className="col-auto p-0 me-3">
						<Link href={"/kundendienst"}
							as={t("/kundendienst")}>
							<a className={`pb-1 nav-link-black ${router.asPath === t("/kundendienst") ? "nav-link-active" : ""}`}>
								<Typography variant="bodySm"
									semanticTag="span"
									style={{color: "#6C7074"}}>{t("Kundendienst")}</Typography>
							</a>
						</Link>
					</li>

				</ul>
				<ul className="d-flex list-unstyled align-items-center position-relative mb-0 ms-3 ps-3"
					style={{borderLeft: "1px solid #DEE2E6"}}>
					{customLanguageSwitcher || <NavDropdown languageSwitcher
						typographyVariant="bodySm"
						isChevronIcon
						align="end"/>}
				</ul>
			</div>
		</div>
	)
}

export default DesktopTopHeader

import React from "react"
import Image from "next/image"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"

type Props = {
    onClose?: () => {};
};

const AfterSubmitModalContent: React.FC<Props> = ({
	onClose
}) => {
	const {t} = useTranslation()

	return (
		<div className="bg-white text-dark p-4"
			style={{borderRadius: 16, maxWidth: 450}}>
			<div onClick={onClose}
				className="d-flex justify-content-end cursor-pointer mb-3">
				<Image src="/assets/icons/close-icon.svg"
					width={16}
					height={16} />
			</div>
			<div>
				<Typography variant="bodyLg"
					semanticTag="p">
					{t("after-submit-primary-text")}
				</Typography>
				<Typography variant="bodyLg"
					semanticTag="p">
					{t("after-submit-secondary-text")}
				</Typography>
			</div>
		</div>
	)
}

export default AfterSubmitModalContent

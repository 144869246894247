import React, {useContext, useState} from "react"
import PropTypes from "prop-types"
import {useTranslation} from "next-i18next"
import {useRouter} from "next/router"
import Dropdown from "../../reusable/components/Dropdown/Dropdown"
import NavDropdownItem from "./NavDropdownItem"
import {CaretDownFill, ChevronDown} from "react-bootstrap-icons"
import Typography, {Variants} from "../../reusable/Typography/Typography"
import {localisedPath} from "../../../utility/Helper"
import DataContext from "../../../context/DataContext"
import FreeServiceMenuItem from "../../reusable/FreeServiceMenuItem"
import {serviceLabel, serviceType} from "./DesktopTopHeader"
import RyderAiFreeService from "./RyderAiFreeService"

type Props = {
	label?: string
	title?:serviceLabel
  classGroup?: string,
  align?: string
  disabled?: boolean
  isActive?: boolean
	items?: { itemLabel: string, href: string }[]
  services?:serviceType[]
  action?: () => void,
  languageSwitcher?: boolean,
  sortDropDown?: boolean
  isNoUppercase?: boolean
  typographyVariant?: keyof typeof Variants
  isChevronIcon?: boolean
	styles?: any
	isFreeServices?: boolean
	isRyderAiSection?:boolean
}

const NavDropdown = ({
	label,
	classGroup,
	items,
	align = "start",
	languageSwitcher,
	action,
	isNoUppercase,
	typographyVariant,
	isChevronIcon,
	styles,
	isFreeServices,
	title,
	services,
	isRyderAiSection = false
}: Props): React.ReactElement => {
	const {t} = useTranslation()
	const {data} = useContext(DataContext)
	const router = useRouter()
	const [isOpen, setIsOpen] = useState(false)
	const pathParts = router.pathname.split("/").filter(element => element !== "")
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const isBlogPost = pathParts[0] === "blog" && pathParts.length > 1
	const isConfirmationPage = pathParts[0] === "checkout"

	const handleMouseEnter = () => {
		if (action) {
			action()
		}

		setIsOpen(true)
	}

	const handleMouseLeave = () => {
		setIsOpen(false)
	}

	const handleChangeLanguage = async () => {
		const targetLanguage = router.locale === "de" ? "fr" : "de"

		setIsLoading(true)
		const url = localisedPath(router.asPath, t, isBlogPost ? router.pathname : router.asPath, isBlogPost ? data.slugs[targetLanguage] : isConfirmationPage ? router.query.orderNumber : null)
		router.push(url, null, {locale: targetLanguage}).then(
			() => setIsLoading(false)
		)
	}

	return (
		<>
			{languageSwitcher && isLoading ?
				<div className="spinner-border spinner-border-sm text-primary"
					role="status">
					<span className="visually-hidden">Loading...</span>
				</div> :
				languageSwitcher && !isLoading ?
					<li style={{zIndex: 14000}}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						className={`position-relative pointer col-auto p-0 ${align === "start" ? "me-4" : ""}`}>
						<span>
							<Typography variant={typographyVariant ? typographyVariant : "bodyLg"}
								semanticTag="span"
								className={`text-uppercase text-secondary ${classGroup} ${isOpen && "nav-link-active"}`}>{t("target-language").toLocaleUpperCase()}</Typography>
							{isChevronIcon ? <ChevronDown className="ms-2"
								style={{transform: `${isOpen ? "rotate(180deg)" : ""}`, transition: "250ms", ...styles}}/> : <CaretDownFill className="ms-2 mb-1"/>}
						</span>
						<Dropdown top={25}
							left={-5}
							isOpen={isOpen}>
							<ul style={{borderRadius: "8px"}}
								className="bg-white border px-2 py-2 row list-unstyled">
								<NavDropdownItem label={router.locale === "de" ? "FR" : "DE"}
									action={handleChangeLanguage}
									langSwitcher/>
							</ul>
						</Dropdown>
					</li> :
					isFreeServices ?
						<li style={{zIndex: 14000}}
							onMouseLeave={handleMouseLeave}
							className={`position-relative pointer col-auto p-0 ${align === "start" ? "me-4" : "ms-4"} text-bold}`}>
							<span onMouseEnter={handleMouseEnter} >
								<Typography variant={typographyVariant ? typographyVariant : "bodyLgBold"}
									semanticTag="span"
									className={`pb-1 ${isNoUppercase ? "" : "text-uppercase"} ${classGroup} ${isOpen && "nav-link-active"}`}>{title(t)}</Typography>
								{isChevronIcon ? <ChevronDown className="ms-2"
									style={{transform: `${isOpen ? "rotate(180deg)" : ""}`, transition: "250ms"}}/> : <CaretDownFill className="ms-2 mb-1"/>}
							</span>
							<Dropdown top={25}
								left={-5}
								isOpen={isOpen}>
								<ul style={{minWidth: "364px", borderRadius: "8px", overflowY: "auto"}}
									className="bg-white border px-2 py-2 pb-4 row list-unstyled">
									{services?.map((service, index) => (<React.Fragment key={index}>
										{service.title(t) === t("ask-ai") ? <RyderAiFreeService /> : <>
											<FreeServiceMenuItem service={service} />
											{index < services.length - 1 && (
												<div className="col-12 px-3 mt-2">
													<div className="border-top mb-2" />
												</div>
											)}
										</>}
									</React.Fragment>
									))}
								</ul>
							</Dropdown>
						</li> :
						<li style={{zIndex: 14000}}
							onMouseLeave={handleMouseLeave}
							className={`position-relative pointer col-auto pl-4 p-0 ${align === "start" ? "me-4" : "ms-4"} text-bold}`}>
							<span onMouseEnter={handleMouseEnter}>
								<Typography variant={typographyVariant ? typographyVariant : "bodyLgBold"}
									semanticTag="span"
									className={`pb-1 pl-3 ${isNoUppercase ? "" : "text-uppercase"} ${items?.map(({href}) => t(href)).includes(router.asPath) || isOpen ? "nav-link-active" : ""}${classGroup}`}>{t(label)}</Typography>
								{isChevronIcon ? <ChevronDown className="ms-2"
									style={{transform: `${isOpen ? "rotate(180deg)" : ""}`, transition: "250ms"}}/> : <CaretDownFill className="ms-2 mb-1"/>}
							</span>
							<Dropdown top={25}
								left={-5}
								isOpen={isOpen}>
								<ul style={{minWidth: "234px", borderRadius: "8px"}}
									className="bg-white border px-2 py-2 row list-unstyled">
									{items?.map(({itemLabel, href}) => <NavDropdownItem
										href={href === "/faq" ? `/faq${t("/konzept")}` : t(href)}
										label={itemLabel}
										key={itemLabel}/>)}
								</ul>
							</Dropdown>
						</li>}
		</>
	)
}

NavDropdown.propTypes = {
	label: PropTypes.string,
	classGroup: PropTypes.string,
	align: PropTypes.string,
	items: PropTypes.array
}

export default NavDropdown

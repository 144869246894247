import React from "react"
import Typography, {Variants} from "../Typography/Typography"
import RichTextParagraph from "./RichTextParagraph"
import PropTypes from "prop-types"
import RichTextUL from "./RichTextUL"

type Props = {
  content: any,
  style?: any,
  textAlign?: string,
  isMobile?: boolean
  paragraphVariant?: keyof typeof Variants
  className?: string,
	strapiData?: boolean
	isHomePage?: boolean
}

const RichText = ({
	content,
	style,
	isMobile,
	textAlign = "center",
	paragraphVariant = "bodyLg",
	className = "mb-4",
	strapiData,
	isHomePage = false
}: Props) => {
	return (
		<>
			{content?.map(({nodeType, content}, index) => {
				switch (nodeType) {
					case "heading-1":
						return strapiData ? <Typography style={{fontSize: isHomePage ? isMobile ? "28px" : "36px" : "auto", marginBottom: isHomePage ? "8px" : "", lineHeight: isHomePage ? isMobile ? "36px" : "44px" : "auto"}}
							key={index}
							semanticTag="h1"
							variant="heading1"
							htmlContent={content}/> : content.map((item, idx) => {
							const {value} = item
							const lines = (value || "").split("\n")
							const htmlValue = lines.join("<br/>")
							return (
								<Typography key={idx}
									semanticTag="h1"
									variant="heading1"
									htmlContent={htmlValue}/>
							)
						})
					case "heading-2":
						return strapiData ? <Typography style={{fontSize: isHomePage ? "16px" : "auto", lineHeight: isHomePage ? "24px" : "auto", marginBottom: isHomePage ? "8px" : "", fontWeight: 700}}
							key={index}
							semanticTag="h2"
							variant="heading2">
							{content}
						</Typography> : content.map((item, idx) => {
							return (
								<Typography key={idx}
									semanticTag="h2"
									variant="heading2">
									{item.value}
								</Typography>
							)
						})
					case "heading-3":
						return strapiData ? <Typography key={index}
							style={{fontSize: isHomePage ? "16px" : "auto", lineHeight: isHomePage ? "24px" : "auto", marginBottom: isHomePage ? "16px" : "", fontWeight: 700}}
							semanticTag="h3"
							variant="heading3">
							{content}
						</Typography> : content.map((item, idx) => {
							return (
								<Typography key={idx}
									semanticTag="h3"
									variant="heading3">
									{item.value}
								</Typography>
							)
						})
					case "heading-4":
						return strapiData ? <Typography key={index}
							semanticTag="h4"
							variant="heading3">
							{content}
						</Typography> : content.map((item, idx) => {
							return (
								<Typography key={idx}
									semanticTag="h4"
									variant="heading3">
									{item.value}
								</Typography>
							)
						})
					case "heading-5":
						return strapiData ? <Typography key={index}
							semanticTag="h5"
							variant="heading3">
							{content}
						</Typography> : content.map((item, idx) => {
							return (
								<Typography key={idx}
									semanticTag="h5"
									variant="heading3">
									{item.value}
								</Typography>
							)
						})
					case "heading-6":
						return strapiData ? <Typography key={index}
							semanticTag="h6"
							variant="heading6">
							{content}
						</Typography> : content.map((item, idx) => {
							return (
								<Typography key={idx}
									semanticTag="h6"
									variant="heading6">
									{item.value}
								</Typography>
							)
						})
					case "paragraph":
						return <RichTextParagraph style={style}
							key={index}
							textAlign={textAlign}
							className={className}
							content={content}
							variant={paragraphVariant}/>

					case "unordered-list":
						return <RichTextUL key={index}
							isMobile={isMobile}
							textAlign={textAlign}
							content={content}
							variant={paragraphVariant}/>

					default:
						return null
				}
			})}
		</>
	)
}

RichText.propTypes = {
	content: PropTypes.any.isRequired
}

export default RichText

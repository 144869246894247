import React from "react"
import styles from "./WheelOptionsBorders.module.scss"

type Props = {
    optionsLength: number;
    theme: "default" | "christmas" | "newyear" | "love" | "spring";
};

const WheelOptionsBorders: React.FC<Props> = ({optionsLength, theme}) => {
	return (
		<div className={`${styles["option-borders"]} 
        ${styles[`theme-${theme}`]} ${styles[`options-${optionsLength}`]}`}>
			{Array.from({length: optionsLength}).map((_, index) => (
				<div key={index}
					className={styles.line}></div>
			))}
		</div>
	)
}

export default WheelOptionsBorders

import React, {useContext, useEffect, useRef, useState} from "react"
import StoryContext, {Info, StoryItem} from "../../context/StoryContext"
import PropTypes from "prop-types"
import {ModalContext} from "../../context/ModalContext"
import LayoutContext from "../../context/LayoutContext"
import StoryFeaturedBike from "./StoryFeaturedBike"
import YoutubeVideoContainer from "./StoryVideoContainer"
import AnalyticsContext from "../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import {fetchPost} from "../../firebaseAdmin/fetchPost"

type Props = {
	stories?: StoryItem[],
	currentStory?: StoryItem
}

const YoutubeStoryEmbed = ({stories = [], currentStory = null}:Props) => {
	const [info, setInfo] = useState<Info>(null)
	const playerRef = useRef(null)
	const player = playerRef?.current
	const [story, setStory] = useState<StoryItem>(currentStory || stories[0])
	const [loading, setLoading] = useState<boolean>(false)
	const [isSeen, setIsSeen] = useState<boolean>(false)
	const [isLiked, setIsLiked] = useState<boolean>(false)
	const {closeStory} = useContext(ModalContext)
	const {isMobile} = useContext(LayoutContext)
	const videoContainerRef = useRef<HTMLDivElement>(null)
	const [bikeFetched, setBikeFetched] = useState(false)
	const [bikeFetchedClassName, setBikeFetchedClassName] = useState("")
	const {reactGA} = useContext(AnalyticsContext)

	useEffect(() => {
		setBikeFetched(false)
	}, [currentStory?.featuredBike?.slug])

	const markStoryAsSeen = async () => {
		const res = await fetchPost("/api/firestore/add-story-interaction", {
			storyId: story.id
		})
		return res.json()
	}

	useEffect(() => {
		const storiesState = JSON.parse(localStorage.getItem("seenStories") || "[]")
		const storiesInteractionState = JSON.parse(localStorage.getItem("likedStories") || "[]")
		setIsLiked(storiesInteractionState?.[story.id]?.isLiked)
		if (!storiesState?.[story.id]?.isSeen) {
			localStorage.setItem("seenStories", JSON.stringify({...storiesState, [story?.id]: {isSeen: true}}))
			window.dispatchEvent(new Event("sessionStorage"))
			markStoryAsSeen().finally(() => {
				setIsSeen(() => true)
			})
		}
	}, [story])

	useEffect(() => {
		try {
			if (player) {
				player.currentTime = 0
			}
		} catch (error) {

		}
	}, [player])

	useEffect(() => {
		if (bikeFetched && story?.featuredBike?.slug) {
			setBikeFetchedClassName(() => bikeFetched && story?.featuredBike?.slug && !isMobile ? "slide-left" : "")
			setTimeout(() => {
				setBikeFetchedClassName(() => "")
				setBikeFetched(() => false)
			}, 600)
		}
	}, [bikeFetched])

	const getCurrentIndex = () => stories.indexOf(story)
	const getNextIndex = () => getCurrentIndex() + 1
	const getPreviousIndex = () => getCurrentIndex() - 1

	const next = (swiped = false) => {
		reactGA?.event({
			category: gaCategories.homePage,
			action: `${story?.label}: ${gaEvents.storyWatched}  ${info?.currentTime || 0}`,
			label: `story ${story?.label} watch time ${info?.currentTime || 0}`,
			nonInteraction: false
		})
		if (videoContainerRef?.current?.classList) {
			videoContainerRef?.current?.classList?.remove("slide-left", "rotate-left", "rotate-right", "slide-out-right", "slide-in-left")
		}

		const nextIndex = getNextIndex()

		reactGA?.event({
			category: gaCategories.homePage,
			action: ` ${gaEvents.goToNextStory} towards ${stories[nextIndex < stories.length ? nextIndex : 0]?.label} `,
			label: ` ${gaEvents.goToNextStory} ${stories[nextIndex < stories.length ? nextIndex : 0]?.label} `,
			nonInteraction: false
		})
		if (isMobile && videoContainerRef?.current?.classList) {
			videoContainerRef?.current?.classList?.add("slide-out-left")
			setTimeout(() => {
				setStory(stories[nextIndex < stories.length ? nextIndex : 0])

				videoContainerRef?.current?.classList?.remove("slide-out-left")
				videoContainerRef?.current?.classList?.add("slide-in-right")

				setTimeout(() => {
					videoContainerRef?.current?.classList?.remove("slide-in-right")
				}, 300)
			}, 300)
		} else if (videoContainerRef?.current?.classList) {
			videoContainerRef?.current?.classList?.add(swiped ? "rotate-left" : "rotate-right")

			setTimeout(() => {
				setStory(stories[nextIndex <= stories.length - 1 ? nextIndex : 0])
				videoContainerRef?.current?.classList?.remove(swiped ? "rotate-left" : "rotate-right")
			}, 300)
		}
	}

	const previous = (swiped = false) => {
		reactGA?.event({
			category: gaCategories.homePage,
			action: `${story?.label}: ${gaEvents.storyWatched}  ${info?.currentTime || 0}`,
			label: `story ${story?.label} watch time ${info?.currentTime || 0}`,
			nonInteraction: false
		})
		if (videoContainerRef?.current?.classList) {
			videoContainerRef?.current?.classList?.remove("slide-left", "rotate-left", "rotate-right", "slide-out-right", "slide-in-left")
		}

		const previousIndex = getPreviousIndex()

		reactGA?.event({
			category: gaCategories.homePage,
			action: `${gaEvents.goToPreviousStory} towards ${stories[previousIndex < stories.length ? previousIndex : 0]?.label} `,
			label: `go to previous story ${stories[previousIndex < stories.length ? previousIndex : 0]?.label} `,
			nonInteraction: false
		})

		if (isMobile && videoContainerRef?.current?.classList) {
			videoContainerRef?.current?.classList?.add("slide-out-right")
			setTimeout(() => {
				setStory(stories[previousIndex >= 0 ? previousIndex : stories.length - 1])

				videoContainerRef?.current?.classList?.remove("slide-out-right")
				videoContainerRef?.current?.classList?.add("slide-in-left")

				setTimeout(() => {
					videoContainerRef?.current?.classList?.remove("slide-in-left")
				}, 300)
			}, 300)
		} else if (videoContainerRef?.current?.classList) {
			videoContainerRef?.current?.classList?.add(swiped ? "rotate-right" : "rotate-left")

			setTimeout(() => {
				const previousIndex = getPreviousIndex()
				setStory(stories[previousIndex >= 0 ? previousIndex : stories.length - 1])
				videoContainerRef?.current?.classList?.remove(swiped ? "rotate-right" : "rotate-left")
			}, 300)
		}
	}

	const playFromStart = () => {
		try {
			if (player) {
				player.currentTime = 0
			}
		} catch (error) {

		}
	}

	useEffect(() => {
		setLoading(true)
	}, [story])

	useEffect(() => {
		const handleEsc = (event: KeyboardEvent) => {
			if (event.key === "Escape") {
				closeStory()
			}
		}

		const handleArrowKeys = (event: KeyboardEvent) => {
			if (event.key === "ArrowRight") {
				next()
			} else if (event.key === "ArrowLeft") {
				previous()
			}
		}

		window.addEventListener("keydown", handleEsc)
		window.addEventListener("keydown", handleArrowKeys)

		return () => {
			window.removeEventListener("keydown", handleEsc)
			window.removeEventListener("keydown", handleArrowKeys)
		}
	}, [closeStory, story])
	return (
		<StoryContext.Provider value={{stories, next, previous, playFromStart, getCurrentIndex, info}}>
			<div className={"position-fixed top-0 start-0 bottom-0 end-0 "}
				style={{
					background: "rgba(33, 37, 41, 0.88)",
					zIndex: 9999999999
				}}
				onClick={() => {
					closeStory()
				}}>
				<div className={"container"}>
					<div style={{height: "100dvh"}}
						className={"row justify-content-center align-items-center"}>
						<div className={"col-12"}>
							<div style={{columnGap: "4rem"}}
								className={`${story?.featuredBike?.[0]?.slug && !isMobile ?
									"d-flex align-items-center justify-content-center" :
									"row justify-content-center"}`}>
								<YoutubeVideoContainer
									loading={loading}
									isSeen={isSeen}
									setIsLiked={setIsLiked}
									isLiked={isLiked}
									videoContainerRef={videoContainerRef}
									ref={playerRef}
									bikeFetched={bikeFetched}
									setBikeFetched={setBikeFetched}
									className={`${bikeFetchedClassName}`}
									next={next}
									player={player}
									previous={previous}
									setInfo={setInfo}
									setLoading={setLoading}
									stories={stories}
									story={story}
								/>
								{story?.featuredBike?.[0]?.slug && !isMobile ? (
									<StoryFeaturedBike
										cta={story?.featuredBike?.cta}
										className={`${bikeFetched ? "slide-up" : ""}`}
										setBikeFetched={setBikeFetched}
										slug={story?.featuredBike?.[0]?.slug}
									/>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		</StoryContext.Provider>
	)
}

YoutubeStoryEmbed.propTypes = {
	videos: PropTypes.array,
	currentStory: PropTypes.object
}

export default YoutubeStoryEmbed

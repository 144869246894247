import React from "react"
import Typography from "../reusable/Typography/Typography"
import styles from "./WheelOfFortuneForm.module.scss"
import WheelOfFortuneFormContent from "./WheelOfFortuneFormContent"
import {useTranslation} from "next-i18next"

type Props = {
    selectedOption: any,
    formId: string,
    onClose: () => void;
    theme: "default" | "christmas" | "newyear" | "love" | "spring";
};

const WheelOfFortuneForm: React.FC<Props> = ({selectedOption, formId, onClose, theme}) => {
	const {t} = useTranslation()

	return (
		<section className={`${styles["success-section"]} ${styles[`theme-${theme}`]}
        d-flex flex-column align-items-center`}>
			<div className={styles.lights}>
				{Array.from({length: 8}).map((_, index) => (
					<div key={index}
						className={styles.circle}></div>
				))}
			</div>
			<div className={`${styles["success-notification"]} mt-3 mb-4 position-relative`}>
				<div className={`${styles["success-content"]} 
                d-flex flex-column align-items-center justify-content-center`}>
					<Typography variant="bodySmBold"
						className={`px-4 text-center ${styles["success-content-top-desc"]}`}>
						{t("what-a-spin")}
					</Typography>
					<Typography variant="heading1Black"
						className={styles["success-content-title"]}
						style={{fontSize: 64, lineHeight: "75px"}}>
						{selectedOption?.mainText}
					</Typography>
					<Typography variant="bodyXSmBold"
						className={`mt-2 ${styles["success-content-desc"]}`}>
						{t(selectedOption?.secondaryText)}
					</Typography>
				</div>

				<div className={styles.badge}>
				</div>
			</div>
			<Typography variant="bodySmBold"
				style={{maxWidth: 344}}
				className="text-center mb-4 text-white">
				{t("how-to-contact")}
			</Typography>
			<WheelOfFortuneFormContent formId={formId}
				onClose={onClose}/>
		</section>
	)
}

export default WheelOfFortuneForm

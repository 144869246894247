import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import Link from "next/link"
import {useTranslation} from "next-i18next"
import {useRouter} from "next/router"
import Typography, {Variants} from "../../reusable/Typography/Typography"
import {ChevronDown} from "react-bootstrap-icons"

type Props = {
	label: string
	href: string
	classGroup?: string,
	className?: string,
	align?: string
	action?: () => void
	isNoUppercase?: boolean
	typographyVariant?: keyof typeof Variants
	isChevronIcon?: boolean
	onMouseLeave?: () => void
	activeItem?: string
	itemTitle?: any
	hasNoMargin?: boolean
	icon?: React.ReactNode
	noChevron?: boolean
	isLink?: boolean
    iconFirst?: boolean
}

const NavItem = ({
	label,
	href,
	classGroup,
	className,
	align = "start",
	action,
	onMouseLeave,
	activeItem,
	itemTitle = "",
	isNoUppercase,
	typographyVariant,
	isChevronIcon,
	hasNoMargin,
	icon,
	noChevron,
	isLink = true,
	iconFirst = true
}: Props): React.ReactElement => {
	const {t} = useTranslation()
	const router = useRouter()
	const [styles, setStyles] = useState("")

	useEffect(() => {
		setStyles(`text-bold col-auto d-flex 
            ${hasNoMargin ? "" : align === "start" ? "me-4" : "ms-5"}  
            ${(router.asPath === href || itemTitle === activeItem) ? "nav-link-active" : ""} 
            ${classGroup} ${className ? className : ""}`)
	}, [router.asPath])

	return (
		<li style={{zIndex: 3000, height: "100%", padding: "8px 12px 0px"}}
			onMouseEnter={action}
			onMouseLeave={onMouseLeave}
			className={styles}>
			{iconFirst && icon && <div className="me-2">{icon}</div>}
			{isLink ?
				<Link href={href}
					as={t(href)}
					replace>
					<a className={`${isNoUppercase ? "" : "text-uppercase"} d-flex align-items-start text-dark text-decoration-none`}>
						<Typography variant={typographyVariant ? typographyVariant : "bodyLgBold"}
							semanticTag="span">
							{t(label)}
						</Typography>
					</a>
				</Link> : <Typography variant={typographyVariant ? typographyVariant : "bodyLgBold"}
					semanticTag="span"
					className="text-dark">
					{t(label)}
				</Typography>}

			{!iconFirst && icon && <div className="ms-2">{icon}</div>}

			{!noChevron && (isChevronIcon && activeItem === itemTitle ? (
				<ChevronDown
					className="ms-2 mt-1"
					style={{transform: "rotate(180deg)", transition: "250ms", marginTop: 2}}
				/>
			) : (
				<ChevronDown className="ms-2"
					style={{transition: "250ms", marginTop: 2}} />
			))}
		</li>
	)
}

NavItem.propTypes = {
	label: PropTypes.string.isRequired,
	href: PropTypes.string.isRequired,
	classGroup: PropTypes.string,
	className: PropTypes.string,
	align: PropTypes.string
}

export default NavItem

import React, {useContext} from "react"
import {useTranslation} from "next-i18next"
import Typography from "../Typography/Typography"
import {X} from "react-bootstrap-icons"
import Image from "next/image"
import BookAppointmentButton from "../ModalButtons/BookAppointmentButton"
import DataContext from "../../../context/DataContext"
import RichText from "../render/RichText"
import NewsletterModalContent from "./NewsletterModalContent"
import Button from "../components/Button"
import {ModalContext} from "../../../context/ModalContext"
import AskAQuestion from "../../bikeDetails/AskAQuestion"
import getImageUrl from "../../../strapi/image/image"
import styles from "./CustomModal.module.scss"

enum Action {
    newsletter = "Newsletter",
    bookConsulation = "Book Consulation",
}

const CustomModalContent = () => {
	const {t} = useTranslation()
	const {pageProps: {customModal}} = useContext(DataContext)
	const {closeModal, openModal, setModalContent} = useContext(ModalContext)
	return (
		<>
			{
				customModal.map(({fields}, index) => {
					const {title, text, action, picture, pictureMobile} = fields
					return (
						<div key={index}
							className={`${styles.modalWrapper} position-relative px-md-0 `}>

							<div onClick={() => closeModal()}
								className={"position-absolute bg-white rounded-circle top-0 end-0"}
								style={{
									marginTop: "2%",
									marginRight: "3%",
									zIndex: 1
								}}>
								<X size={30}
									color={"black"}/>
							</div>

							<div style={{minHeight: 208, borderTopRightRadius: "16px", borderTopLeftRadius: "16px"}}
								className="d-md-none col-12 p-0 ps-2 overflow-hidden position-relative">
								<Image src={getImageUrl(pictureMobile)}
									objectFit="cover"
									layout="fill"/>
							</div>

							<div className="row position-relative mt-md-3 mx-0 justify-content-between">
								<div className={`${styles.contentWrapper} col-12 col-md-7 bg-white py-md-4 ps-md-4 pe-md-5 d-flex justify-content-center align-items-center`}>
									<div className="container px-0 ps-md-0">
										<div className="row mb-md-1">
											<Typography className="col-12 mb-2 mt-4"
												variant="heading2"
												semanticTag="h2">{title}</Typography>
											<RichText className="mb-2"
												content={text?.content}/>
										</div>
										<div className={"row mb-0"}>
											<div className={"col-12 mb-3"}>
												{action === Action.bookConsulation && <div className="d-flex flex-column flex-md-row">
													<div className="col-12 col-md-5 me-md-4">
														<BookAppointmentButton
															fontSizeSmall
															className="mb-3 mb-md-0"
															textColor="#fff"
															color="white"
															bgColor="#fec04d"
															hoverColor="rgba(254, 192,77, 0.8)"
															label={t("Beratung Buchen")}
															icon={<img src="/assets/icons/calendar-consultation.svg"
																width={24}
																alt=""/>} />
													</div>

													<Button icon={<img src="/assets/icons/share/mail.svg"
														alt="" />}
													className="col-12 col-md-5"
													fontSizeSmall
													onClick={() => {
														setModalContent(<AskAQuestion setAskAQuestion={closeModal}/>)
														openModal()
													}}
													textColor="#212529"
													color="#fff"
													bgColor="#fff"
													hoverColor="#212529"
													outline
													label={t("Stelle eine Frage")}/>
												</div>
												}

												{action === Action.newsletter && <Button
													className="col-12"
													label={t("Absenden")}
													hoverColor="black"
													outline
													onClick={() => {
														setModalContent(<NewsletterModalContent closeModal={closeModal}/>)
														openModal()
													}}
												>
												</Button>}
											</div>
										</div>

									</div>
								</div>

								<div style={{minHeight: 600}}
									className={`${styles.pictureWrapper} d-none d-md-block col-5 p-0 m-0 position-relative overflow-hidden`}>
									<Image src={getImageUrl(picture)}
										objectFit="cover"
										layout="fill"/>
								</div>

							</div>

							<Button
								className="position-absolute bottom-0 start-0 ms-4 mb-4 d-none d-md-block"
								style={{marginTop: "90%",
									marginLeft: "10%",
									zIndex: 1
								}}
								paddingX="0"
								textColor="#212529"
								hoverColor=""
								isNoUppercase
								color="black"
								bgColor="white"
								label={t("Abbrechen")}
								fontSizeSmall
								onClick={() => {
									closeModal()
								}}>
							</Button>
						</div>
					)
				})
			}
		</>
	)
}

export default CustomModalContent

import React, {useContext, useEffect} from "react"
import Typography from "../reusable/Typography/Typography"
import DataContext from "../../context/DataContext"
import Button from "../reusable/components/Button"
import {ModalContext} from "../../context/ModalContext"
import BookAppointmentModalContent from "../reusable/ModalsContent/BookAppointmentModalContent"
import CustomModalContent from "../reusable/ModalsContent/CustomModalContent"
import DiscountForNewsletterModalContent from "../reusable/ModalsContent/DiscountForNewsletterModalContent"
import Image from "next/image"

import {useTranslation} from "next-i18next"
import OrganizationContext from "../../context/OrganizationContext"
import WheelOfFortune from "../wheelOfFortune/WheelOfFortune"
import AnalyticsContext from "../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import {getOrganizationDataFromStrapi} from "../../utility/strapi/helpers"

export enum Type {
	newsletter = "embeed_popup",
	bookConsulation = "Book Consulation",
	link = "href",
	customModal = "Custom Modal",
    embeedWheel = "embeed_wheel"
}

type Props = {
	isRounded?: boolean
	style?: any
	hideStickyBar?: boolean
	isPDP?: boolean
	setAddTopPadding?: (boolean)=>void
}

// This component is Strapi migrated
const StickyBar: React.FC<Props> = ({
	isRounded,
	style,
	isPDP,
	hideStickyBar,
	setAddTopPadding = (param:boolean) => null
}) => {
	const {pageProps: {strapiContent}} = useContext(DataContext)
	const {reactGA} = useContext(AnalyticsContext)

	// eslint-disable-next-line
	const sticky_bar = strapiContent?.data?.sticky_bar
	const {setModalContent, closeModal, openModal} = useContext(ModalContext)
	const {t} = useTranslation()

	const {organizationSlug, organizationData} = useContext(OrganizationContext)

	const today = new Date()
	const showWheelDateForLoveWheel = new Date("2025-09-03T00:00:00")
	const loveWheelEndDate = new Date("2025-03-01T00:00:00") // Ends when February is over

	const button = sticky_bar ? sticky_bar?.attributes.button : null

	const text = sticky_bar ? sticky_bar?.attributes.text : null

	const stickyBarData = sticky_bar ? [{
		fields: {
			type: button?.type || Type.link,
			label: text?.content,
			buttonText: button?.label || null,
			link: button?.href || null
		}
	}] : null

	const isTypeWheel = stickyBarData ? stickyBarData[0].fields.type === "embeed_wheel" : false
	const isLoveWheelStyles = isTypeWheel && today >= showWheelDateForLoveWheel && today < loveWheelEndDate

	useEffect(() => {
		setAddTopPadding(stickyBarData && stickyBarData.length)
	}, [])

	const handleButtonClick = label => {
		reactGA.event({
			category: gaCategories.homePage,
			action: gaEvents.stickyBarCTA,
			label,
			nonInteraction: false
		})
	}

	return (
		<>
			{stickyBarData && stickyBarData?.length ?
				<div className={`${hideStickyBar && !isPDP ? "slide-up-off-screen" : isPDP ? "" :
					"slide-down-from-top top-0 end-0 start-0 position-relative"} ${process.env.NEXT_PUBLIC_CUSTOM_INSTANCE && organizationData?.stickyBar?.backgroundColor ? "" : "bg-primary"}
            d-flex justify-content-center align-items-end ${isRounded && "rounded"}`}
				style={{padding: "6px 12px", backgroundColor: process.env.NEXT_PUBLIC_CUSTOM_INSTANCE && organizationData?.stickyBar?.backgroundColor ? organizationData?.stickyBar?.backgroundColor : "", ...style}}>
					<div className={"row justify-content-center mb-1"}>
						{process.env.NEXT_PUBLIC_CUSTOM_INSTANCE && !organizationData?.hideCustomLogos ?
							<div className="d-flex flex-wrap justify-content-center">
								<div className="d-flex align-items-center text-start">
									<div className="d-none d-md-flex align-items-center">
										<img src={organizationData?.tabIcon}
											style={{width: "auto", height: "24px"}}
											alt={organizationSlug} />
									</div>
									<div className="d-md-none d-flex align-items-center">
										<img src={organizationData?.tabIcon}
											style={{width: "auto", height: "20px"}}
											alt={organizationSlug} />
									</div>
									<Typography variant={"bodySm"}
										semanticTag={"span"}
										style={{color: organizationData?.stickyBar?.textColor || ""}}
										className="ms-2 ms-md-3 me-md-1">
										{t(organizationData?.stickyBar?.text)}
									</Typography>
								</div>
								<Button
									className="ms-2"
									outline
									color={organizationData?.stickyBar?.btnTextColor || "#fff"}
									bgColor="transparent"
									textColor={organizationData?.stickyBar?.btnTextColor || "#fff"}
									paddingY="0"
									paddingX="0"
									isNoUppercase
									href={organizationData?.stickyBar?.buttonLink}
								>
									<Typography className="px-1"
										variant={"bodySmBold"}>
										{organizationData?.stickyBar?.buttonCTA}
									</Typography>
								</Button>
							</div> :
							stickyBarData.map(({fields}, index) => {
								const {type, label, link, buttonText} = fields

								return (
									<div className="d-flex flex-wrap justify-content-center "
										key={index}>
										<div className="d-flex align-items-center text-start">
											<div className="d-none d-md-flex align-items-center">
												{type === Type.embeedWheel ?
													<Image src="/assets/icons/wheel-icon-black.svg"
														width="18px"
														height="18px"
														alt="Wheel of fortune"/> :
													<Image src={"/assets/icons/fire.svg"}
														width="24px"
														height="24px"
														alt="fire"/>
												}

											</div>

											<div className="d-md-none d-flex align-items-center">
												{type === Type.embeedWheel ?
													<Image src="/assets/icons/wheel-icon-white.svg"
														width="18px"
														height="18px"
														alt="Wheel of fortune"/> :
													<Image
														src={type === Type.embeedWheel ?
															"/assets/icons/fire.svg" : "/assets/icons/fire.svg"}
														width="20px"
														height="20px"
														alt="fire"/>
												}
											</div>
											<Typography variant={"bodySm"}
												semanticTag={"span"}
												className="ms-2 me-md-1">{label}</Typography>
										</div>

										{type && <>
											{type === Type.newsletter && <Button
												className=""
												outline
												color="#fff"
												hoverColor="#212529"
												bgColor="transparent"
												textColor="#212529"
												paddingY="0"
												paddingX="0"
												isNoUppercase
												onClick={() => {
													setModalContent(<DiscountForNewsletterModalContent
														closeModal={closeModal}/>)
													openModal()
													handleButtonClick(Type.newsletter)
												}}
											>
												<Typography className="px-1"
													variant={"bodySmBold"}>{buttonText}</Typography>
											</Button>}

											{type === Type.embeedWheel && <Button
												className="ms-2"
												outline
												hoverColor="#212529"
												color="#fff"
												borderColor="#212529"
												textColor="#212529"
												paddingY="0"
												paddingX="0"
												isNoUppercase
												onClick={() => {
													setModalContent(<WheelOfFortune onClose={closeModal}
														isOpenedFromStickyBar={true}/>)
													openModal()
													handleButtonClick(Type.embeedWheel)
												}}
											>
												<Typography className="px-1"
													variant={"bodySmBold"}>{buttonText}</Typography>
											</Button>}

											{type === Type.bookConsulation && <Button
												className="ms-2"
												outline
												color="#fff"
												hoverColor="#212529"
												bgColor="transparent"
												textColor="#212529"
												paddingY="0"
												paddingX="0"
												isNoUppercase
												onClick={() => {
													setModalContent(
														<BookAppointmentModalContent closeModal={closeModal}/>
													)
													openModal()
													handleButtonClick(Type.bookConsulation)
												}}
											>
												<Typography className="px-1"
													variant={"bodySmBold"}>{buttonText}</Typography>
											</Button>
											}

											{
												type === Type.customModal && <Button
													className="ms-2"
													outline
													color="#fff"
													hoverColor="#212529"
													bgColor="transparent"
													textColor="#212529"
													paddingY="0"
													paddingX="0"
													isNoUppercase
													onClick={() => {
														setModalContent(<CustomModalContent/>)
														openModal()
														handleButtonClick(Type.customModal)
													}}
												>
													<Typography className="px-1"
														variant={"bodySmBold"}>{buttonText}</Typography>
												</Button>
											}

											{
												type === Type.link && <Button
													className="ms-2"
													outline
													color="#212529"
													hoverColor="#fff"
													bgColor="transparent"
													textColor="#fff"
													paddingY="0"
													paddingX="0"
													isNoUppercase
													href={link}
													target={"_blank"}
													onClick={() => handleButtonClick(Type.link)
													}
												>
													<Typography className="px-1"
														variant={"bodySmBold"}>{buttonText}</Typography>
												</Button>
											}
										</>}

									</div>
								)
							})
						}
					</div>
				</div> : null}
		</>
	)
}

export default StickyBar

import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import AnimationComponent from "../../reusable/components/AnimationComponent/AnimationComponent"
import {MenuTitlesArray} from "../../../utility/Helper"
import NewMobileMegaMenu from "./NewMobileMegaMenu"
import dynamic from "next/dynamic"
import Spinner from "../../reusable/Spinner"
import {onMegaMenuBtnClick} from "./NavigationTabs"
import {useRouter} from "next/router"
import Search from "../../reusable/Search"

type Props = {
	absolute: boolean;
	menuTitles: MenuTitlesArray[];
	stickyTop?: boolean;
	isHidden: boolean;
};

const DynamicSearchContent = dynamic(() => import("../../search/SearchContent"), {
	loading: () => (
		<div>
			<Spinner />
		</div>
	)
})

const MobileNavBar = ({stickyTop, isHidden}: Props) => {
	const router = useRouter()
	const {pathname} = router
	const [isBackgroundOn, setIsBackgroundOn] = useState(false)
	const isMenuVisible = router?.query?.megamenu === "open"
	const toggleMenu = e => {
		e.stopPropagation()
		onMegaMenuBtnClick(router, pathname)
	}

	useEffect(() => {
		if (!isBackgroundOn || !isMenuVisible) {
			document.body.style.overflow = "auto"
		} else if (isBackgroundOn) {
			document.body.style.overflow = "hidden"
		}

		return () => {
			document.body.style.overflow = "auto"
		}
	}, [isBackgroundOn, isMenuVisible])

	return (
		<div
			style={{zIndex: 100000, borderRadius: "0px 0px 16px 16px !important"}}
			className={` ${
				isHidden ? "hidden" : ""
			} d-lg-none py-0  bg-white overflow-auto mobile-navbar`}
		>
			<div>

				<AnimationComponent type="slideRight"
					inProp={isMenuVisible}>
					<div
						className={"position-fixed vh-100 w-100 border-top overflow-auto"}
						style={{zIndex: 99999, top: 0}}
						onClick={toggleMenu}>
						<NewMobileMegaMenu showSearch
							onMegaMenuBtnClick={onMegaMenuBtnClick} />
					</div>
				</AnimationComponent>
			</div>
			<Search />
		</div>
	)
}

MobileNavBar.propTypes = {
	absolute: PropTypes.bool.isRequired
}

export default MobileNavBar

import Image from "next/image"
import React from "react"
import styles from "./WheelOfFortuneModal.module.scss"

type Props = {
    theme: "default" | "christmas" | "newyear" | "love" | "spring";
}

const WheelDecoration: React.FC<Props> = ({theme}) => {
	return (
		<>
			{theme === "christmas" ?
				<div className={`${styles["ice-overlay-top"]} position-absolute start-0`}
					style={{top: -25, width: "100%"}}>
					<Image src="/assets/icons/ice-overlay-top-desktop.svg"
						width={"100%"}
						height={100}
						layout="fill"
						style={{top: 0}}
					/>
				</div> :
				theme === "newyear" ?
					<div
						className="w-100"
						style={{
							overflow: "hidden",
							whiteSpace: "nowrap"
						}}
					>
						{Array.from({length: 6}).map((_, index) => (
							<div
								key={index}
								className={`${styles["new-year-lights"]} px-4 position-absolute`}
								style={{
									top: 0,
									transform: "translateX(-50px) rotate(-1deg)",
									left: `${index * 395}px`,
									width: "auto"
								}}>
								<div className="d-none d-md-block">
									<Image
										src="/assets/icons/new-year-lights.svg"
										width={400}
										height={90}
										layout="fixed"
										style={{
											top: 0,
											objectFit: "contain"
										}}
									/>
								</div>
								<div className="d-md-none">
									<Image
										src="/assets/icons/new-year-lights.svg"
										width={460}
										height={90}
										layout="fixed"
										style={{
											top: 0,
											objectFit: "contain"
										}}
									/>
								</div>
							</div>
						))}
					</div> :
					theme === "love" ?
						<div className="d-flex position-absolute justify-content-center"
							style={{
								top: 0,
								left: "50%",
								transform: "translate(-50%)"
							}}>
							<Image
								src="/assets/icons/love-wheel-decoration.svg"
								width={289}
								height={128}
								layout="fixed"
							/>
						</div> :
						theme === "spring" ?
							<div className={`${styles["spring-leaves-top"]} position-absolute start-0`}
								style={{top: -20, width: "100%"}}>
								<Image src="/assets/icons/spring-wheel-mobile-decoration.svg"
									width={"100%"}
									height={100}
									layout="fill"
									style={{top: 0}}
									className="d-md-none"
								/>
								<Image src="/assets/icons/spring-wheel-desktop-decoration.svg"
									width={"100%"}
									height={100}
									layout="fill"
									style={{top: 0}}
									className="d-none d-md-block"
								/>
							</div> :
							null
			}

		</>
	)
}

export default WheelDecoration

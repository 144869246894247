import React, {useEffect, useState} from "react"
import Image from "next/image"
import PropTypes from "prop-types"
import Typography from "../../reusable/Typography/Typography"
import {useSwipeable} from "react-swipeable"
import LinkWithIcon from "../../reusable/components/LinkWithIcon"
import {useTranslation} from "react-i18next"
import BookAppointmentButton from "../../reusable/ModalButtons/BookAppointmentButton"
import {useRouter} from "next/router"

const NavTooltipsSlider = ({slides}) => {
	const [currentSlide, setCurrentSlide] = useState(0)
	const [isOpen, setIsOpen] = useState(false)
	const {t} = useTranslation(["common"])
	const router = useRouter()
	const wordsToMakeBold = router.locale === "fr" ? 4 : 5
	const arrayOfWords = t("social-proof-label").split(" ")

	const nextSlide = () => {
		setCurrentSlide(prevSlide =>
			prevSlide === slides.length - 1 ? 0 : prevSlide + 1
		)
	}

	const prevSlide = () => {
		setCurrentSlide(prevSlide =>
			prevSlide === 0 ? slides.length - 1 : prevSlide - 1
		)
	}

	useEffect(() => {
		const slideInterval = setInterval(nextSlide, 5000) // 5 seconds
		setIsOpen(false)
		return () => clearInterval(slideInterval)
	}, [currentSlide])

	const handlers = useSwipeable({
		onSwipedLeft: nextSlide,
		onSwipedRight: prevSlide,
		trackMouse: true
	})

	return (
		<div {...handlers}
			className="d-none d-md-flex align-items-center">
			<div>
				{slides.map((product, index) => (
					<div
						key={product.text + index}
						className={`slide ${
							index === currentSlide ?
								"slide-left-enter" :
								index === (currentSlide === 0 ? slides.length - 1 : currentSlide - 1) ?
									"slide-left-exit" :
									""
						} ${index === currentSlide ? "d-flex gap-3" : "d-none"}`}
					>
						{product.link || product.isConsultationLink ?
							product.isConsultationLink ?
								<BookAppointmentButton
									fontSizeSmall
									textColor="#212529"
									icon={<Image src={"/assets/icons/chevron-dark-bg-light.svg"}
										width={24}
										height={24} />}
									color="#212529"
									label={t("Beratung Buchen")}
									linkWithIcon
									textVariant="bodySmBold"
								/> :
								<LinkWithIcon
									href={product.link}
									label={t(product.text)}
									iconBgColor="#EBEBEB"
									iconMargin="ms-2"
									labelVariant={"bodySmBold"}
									padding={"4px 12px"}
									icon={
										<Image src={product.icon}
											width={24}
											height={24}
											alt="chevron right"/>
									}/>							:

							<div className="d-flex align-items-center"
								style={{gap: "16px"}}>
								{product.icon && product.iconFirst &&
								<Image src={product.icon}
									alt="icon"
									width={20}
									height={20}/>
								}

								{product.text === "social-proof-label" ?
									<Typography semanticTag="div"
										variant="bodySm"
										style={{fontSize: "14px", lineHeight: "20px"}}>
										<span style={{fontWeight: 700}}>{arrayOfWords.slice(0, wordsToMakeBold).map(word => word + " ")}</span>
										{arrayOfWords.slice(wordsToMakeBold, arrayOfWords.length).map(word => word + " ")}</Typography> :
									<Typography className="mb-0"
										variant="bodySmBold"
										semanticTag="p">
										{t(product.text)}
									</Typography>}
								{product.icon && !product.iconFirst &&
									<Image src={product.icon}
										alt="icon"
										width={24}
										height={24}/>
								}
								<div
									className="d-flex align-items-center position-relative"
									style={{overflow: "visible"}}
								>
									{product.tooltip && (
										<Image
											className="cursor-pointer d-none d-md-flex align-items-center"
											src={
												isOpen ?
													"/assets/icons/info-active.svg" :
													"/assets/icons/info-transparent.svg"
											}
											alt="tools icon"
											width={24}
											height={24}
											onClick={() => setIsOpen(prev => !prev)}
										/>
									)}
									{isOpen && product.tooltip && (
										<div className="bg-white border p-3 position-absolute tooltip-content">
											{product.tooltip.tooltipComponent ? product.tooltip.tooltipComponent : t(product.tooltip.tooltipText)}
										</div>
									)}
								</div>
							</div>}
					</div>
				))}
			</div>
		</div>
	)
}

NavTooltipsSlider.propTypes = {
	slides: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			tooltip: PropTypes.shape({
				icon: PropTypes.string,
				activeIcon: PropTypes.string,
				component: PropTypes.elementType
			})
		})
	).isRequired
}

export default NavTooltipsSlider

import React, {useContext, useState} from "react"
import axios from "axios"
import Spinner from "../reusable/Spinner"
import styles from "./WheelOfFortuneFormContent.module.scss"
import Button from "../reusable/components/Button"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import FirebaseContext from "../../context/FirebaseContext"

type Props = {
    formId: string;
    portalId?: string;
    onClose?: () => void;
};

const WheelOfFortuneFormContent: React.FC<Props> = ({formId, portalId = "6857669", onClose = () => {}}) => {
	const [isLoading, setIsLoading] = useState(false)
	const [isChecked, setIsChecked] = useState(false)
	const {t} = useTranslation()
	const {user} = useContext(FirebaseContext)

	const [formData, setFormData] = useState({
		firstname: "",
		lastname: "",
		email: "",
		phone: ""
	})

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {name, value} = e.target
		setFormData({...formData, [name]: value})
	}

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault()
		setIsLoading(true)
		if (!user) {
			return
		}

		const idToken = await user.getIdToken()
		const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`

		const payload = {
			fields: [
				{name: "firstname", value: formData.firstname},
				{name: "lastname", value: formData.lastname},
				{name: "email", value: formData.email},
				{name: "phone", value: `+41${formData.phone}`}
			]
		}

		try {
			const response = await axios.post(url, payload, {
				headers: {"Content-Type": "application/json"}
			})

			const data = await fetch("/api/wheelOfFortune/user/markFormAsSubmitted", {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({idToken})
			})
			if (onClose) {
				onClose()
			}
		} catch (error) {
			console.error("Error submitting form:", error)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<section className={styles["form-section"]}>
			{isLoading ? (
				<div style={{height: 300}}>
					<Spinner style={{marginTop: "2rem"}} />
				</div>
			) : (
				<form onSubmit={handleSubmit}>
					<div className="row gx-3  mb-4">
						<div className="col-6">
							<label >
								{t("Vorname")}*
							</label>
							<input
								type="text"
								name="firstname"
								value={formData.firstname}
								onChange={handleChange}
								required
							/>
						</div>
						<div className="col-6">
							<label>
								{t("Nachname")}*
							</label>

							<input
								type="text"
								name="lastname"
								value={formData.lastname}
								onChange={handleChange}
								required
							/>
						</div>
					</div>

					<div className="row gx-3 mb-4">
						<div className="col-6">
							<label>
								{t("email")}*
							</label>

							<input
								type="email"
								name="email"
								value={formData.email}
								onChange={handleChange}
								required
							/>
						</div>
						<div className="col-6">
							<label>
								{t("phone-number")}*
							</label>
							<div className="position-relative">
								<div className={styles.prefix}>+41</div>
								<input
									type="number"
									name="phone"
									value={formData.phone}
									onChange={handleChange}
									required
								/>
							</div>
						</div>
					</div>

					<div className="d-flex gap-3 justify-content-start mb-4">
						<input
							type="checkbox"
							checked={isChecked}
							onChange={e => setIsChecked(e.target.checked)}
							required
						/>
						<label>
							{t("accept-terms-text")}*
						</label>
					</div>
					<Button
						type="submit"
						fontSizeSmall
						bgColor="#FEC04D"
						textColor="#212529"
						color="#212529"
						className="w-100"
						disabled={!isChecked}
					>
						<Typography semanticTag="span"
							variant={"bodySmBlack"}>{t("get-discount")}</Typography>
					</Button>
				</form>
			)}
		</section>
	)
}

export default WheelOfFortuneFormContent

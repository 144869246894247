import React, {useContext, useEffect, useRef, useState} from "react"
import StoryThumbnail from "./StoryThumbnail"
import YoutubeStoryEmbed from "./StoryEmbed"
import AnalyticsContext from "../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import {ModalContext} from "../../context/ModalContext"
import DataContext from "../../context/DataContext"
import {formatImageSrc, getContentFields} from "../../strapi/strapiHelpers"
import {useRouter} from "next/router"

function StoriesSection() {
	const {pageProps: {strapiContent}} = useContext(DataContext)
	const data = strapiContent?.data
	const storiesBase = data?.stories
	// Get route to filter stories to render
	const {asPath} = useRouter()

	// Format strapi data
	const storiesDataStrapi = storiesBase?.map(story => ({
		...getContentFields(story, ["id", "label", "thumbnail", "videoUrl", "cta", "featuredBike", "pagesToShow"]),
		thumbnail: formatImageSrc(story?.attributes?.thumbnail?.data?.attributes?.url),
		pagesToShow: story?.attributes?.pagesToShow?.map(page => page?.slug) || [],
		id: story?.id
	}))

	// Filter by router pathname
	useEffect(() => {
		if (asPath) {
			const filteredStories = storiesDataStrapi?.filter(story => {
				return story?.pagesToShow?.includes(asPath)
			}) || []

			handleIsSeen(filteredStories)
			setStoriesData(filteredStories)

			window.addEventListener("storyClosed", () => setStoriesData(handleIsSeen(filteredStories)))

			return () => window.removeEventListener("storyClosed", () => setStoriesData(handleIsSeen(filteredStories)))
		}
	}, [asPath])

	const [storiesData, setStoriesData] = useState([])
	const {reactGA} = useContext(AnalyticsContext)
	const {setModalContent, openStory, isStoryOpen} = useContext(ModalContext)
	const scrollContainerRef = useRef(null)
	const [isDragging, setIsDragging] = useState(false)
	const [startX, setStartX] = useState(0)
	const [scrollLeft, setScrollLeft] = useState(0)
	const handleMouseDown = e => {
		setIsDragging(true)
		setStartX(e.pageX - scrollContainerRef.current.offsetLeft)
		setScrollLeft(scrollContainerRef.current.scrollLeft)
	}

	const handleMouseMove = e => {
		if (!isDragging) {
			return
		}

		e.preventDefault()
		const x = e.pageX - scrollContainerRef.current.offsetLeft
		const walk = (x - startX)
		scrollContainerRef.current.scrollLeft = scrollLeft - walk
	}

	const handleMouseUp = () => {
		setIsDragging(false)
	}

	const handleMouseLeave = () => {
		setIsDragging(false)
	}

	const handleIsSeen = stories => {
		const storiesState = JSON.parse(localStorage.getItem("seenStories") || "{}")
		if (typeof storiesState !== "undefined") {
			return stories.sort((a, b) => {
				const aSeen = storiesState?.[a.id]?.isSeen
				const bSeen = storiesState?.[b.id]?.isSeen

				return aSeen === bSeen ? 0 : aSeen ? 1 : -1
			})
		}
	}

	const handleClick = story => {
		// Log event
		reactGA?.event({
			category: gaCategories.homePage,
			action: `${gaEvents.openStory} from thumbnail ${story?.label || story.id}`,
			label: `story clicked on from thumbnail ${story?.label || story.id}`,
			nonInteraction: false
		})

		if (typeof window !== "undefined") {
			window.clarity("set", gaEvents.openStory, "User started watching")
		}

		setModalContent(<YoutubeStoryEmbed stories={storiesData as any}
			currentStory={story} />)
		openStory()
	}

	useEffect(() => {
		if (typeof document !== "undefined" && isStoryOpen) {
			document.body.style.overflow = "hidden"
		} else if (!isStoryOpen) {
			document.body.style.overflow = "auto"
		}

		return () => {
			document.body.style.overflow = "auto"
		}
	}, [isStoryOpen])

	if (storiesData?.length) {
		return (
			<div
				ref={scrollContainerRef}
				onMouseDown={handleMouseDown}
				onMouseMove={handleMouseMove}
				onMouseUp={handleMouseUp}
				onMouseLeave={handleMouseLeave}
				style={{
					width: "100%",
					cursor: "grab",
					userSelect: "none",
					height: "98px",
					overflowX: "auto"
				}}
				className={`stories-section
                    pe-4 ps-4 pt-2 d-flex gap-3 
                    ${storiesData.length < 4 ? "justify-content-center" : "justify-content-start"} 
                    justify-content-md-center align-items-center hide-overflow-scrollbar
                `}>
				{storiesData.map((story, index) => (<StoryThumbnail stories={storiesData}
					handleStoryOpen={handleClick}
					key={`Story-${index}`}
					story={story as any} />))}
			</div>
		)
	}

	return null
}

export default StoriesSection

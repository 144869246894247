import React, {useContext, useEffect, useState} from "react"
import styles from "./WheelOfFortune.module.scss"
import Typography from "../reusable/Typography/Typography"
import Image from "next/image"
import WheelOfFortuneModal from "./WheelOfFortuneModal"
import {useRouter} from "next/router"
import FirebaseContext from "../../context/FirebaseContext"
import {useTranslation} from "next-i18next"
import AfterSubmitModalContent from "./AfterSubmitModalContent"
import Spinner from "../reusable/Spinner"
import {logger} from "../../utility/Helper"
import WheelOptionsBorders from "./WheelOptionsBorders"
import {
	BlackFridayOptionsArrOld,
	ChristmasWheelOptionsArr,
	SpringWheelOptionsArr,
	NewYearWheelOptionsArr
} from "../../constants/WheelOfFortuneOptions"

interface Option {
    mainText: string;
    secondaryText: string;
    validUntil: string;
    forms: {
        de: string;
        fr: string;
    };
}

const calculateTimeLeft = (validUntil: string) => {
	const now = new Date()
	const expirationDate = new Date(validUntil)
	const differenceInTime = expirationDate.getTime() - now.getTime()

	if (differenceInTime <= 0) {
		return {days: 0, hours: 0, minutes: 0}
	}

	const days = Math.floor(differenceInTime / (1000 * 60 * 60 * 24))
	const hours = Math.floor((differenceInTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
	const minutes = Math.floor((differenceInTime % (1000 * 60 * 60)) / (1000 * 60))

	return {days, hours, minutes}
}

type Props = {
    onClose?: any
    isOpenedFromStickyBar?: boolean
}

const WheelOfFortune: React.FC<Props> = ({onClose = () => {}, isOpenedFromStickyBar}) => {
	const today = new Date()

	const newYearEndDate = new Date("2025-02-01T00:00:00")
	const SpringWheelStartDate = new Date("2025-03-03T00:00:00") // Starts on March 3

	const SpringWheelEndDate = new Date("2025-05-01T00:00:00") // Ends when May is over

	const isChristmasWheel = false
	const isNewYearWheel = today < newYearEndDate
	const isSpringWheel = today >= SpringWheelStartDate && today < SpringWheelEndDate

	// Determines correct options
	const correctOptions = isSpringWheel ? SpringWheelOptionsArr : isNewYearWheel ?
		NewYearWheelOptionsArr :
		isChristmasWheel ?
			ChristmasWheelOptionsArr :
			BlackFridayOptionsArrOld

	const [options, setOptions] = useState<Option[]>(correctOptions)
	const [rotation, setRotation] = useState(0)
	const [spinning, setSpinning] = useState(false)
	const [selectedOption, setSelectedOption] = useState<Option | null>(null)
	const [highlightedIndex, setHighlightedIndex] = useState(null)
	const [isSpinComplete, setIsSpinComplete] = useState(false)
	const [expiredIndexes, setExpiredIndexes] = useState([])
	const [earliestExpiringIndex, setEarliestExpiringIndex] = useState(null)
	const [showAfterSubmitModal, setShowAfterSubmitModal] = useState(false)
	const {user} = useContext(FirebaseContext)
	const {t} = useTranslation()
	const [showWheel, setShowWheel] = useState(false)
	const [userFormId, setUserFormId] = useState(null)
	const selectedOptionFallback = correctOptions[0]
	const router = useRouter()
	const {locale} = router
	const isLoading = isOpenedFromStickyBar && !(showWheel || userFormId || showAfterSubmitModal)

	// Check preferences to show the wheel or form
	const checkUserPreferences = async () => {
		if (!user) {
			return
		}

		const idToken = await user.getIdToken()
		// Check if wheel should be shown
		const showWheelResponse = await fetch(`/api/wheelOfFortune/user/showWheel?idToken=${idToken}`)
		const showWheelData = await showWheelResponse.json()
		setShowWheel(showWheelData?.showWheel)

		if (showWheelData?.showWheel) {
			await markWheelAsShown(idToken)
			return null
		}

		// Check if the form should be shown
		const showFormResponse = await fetch(`/api/wheelOfFortune/user/showForm?idToken=${idToken}`)
		const showFormData = await showFormResponse.json()
		setShowAfterSubmitModal(!showFormData.showForm)

		const formResponse = await fetch(`/api/wheelOfFortune/user/form?idToken=${idToken}`)

		if (formResponse.status === 404) {
			setUserFormId(null)
			setShowWheel(true)
			return
		}

		if (formResponse.ok) {
			const formData = await formResponse.json()
			if (formData.formId && showFormData.showForm) {
				setUserFormId(formData.formId)
				const foundOption = options.find(option => option.forms[locale] === formData.formId)
				setSelectedOption(foundOption || null)
				setShowWheel(false)
			}
		} else {
			console.error("Error fetching form:", formResponse.statusText)
		}
	}

	const handleFormSubmission = async (formId: string) => {
		if (!user) {
			logger.debug("No user authenticated, skipping form submission.")
			return
		}

		const idToken = await user.getIdToken()

		// Submit the form data
		await fetch("/api/wheelOfFortune/user/form", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({idToken, formId})
		})
	}

	const markWheelAsShown = async (idToken: string) => {
		await fetch("/api/wheelOfFortune/user/markWheelAsShown", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({idToken})
		})
	}

	useEffect(() => {
		if (user) {
			checkUserPreferences()
		}
	}, [user])

	useEffect(() => {
		if (showWheel) {
			const timer = setTimeout(() => {
				spinWheel()
			}, 1000)

			return () => clearTimeout(timer)
		}
	}, [showWheel])

	useEffect(() => {
		const calculateExpirationInfo = () => {
			const expiredIndexes: number[] = []
			let earliestIndex = null
			let earliestTime = {days: Infinity, hours: Infinity, minutes: Infinity}

			options.forEach((option, index) => {
				const timeLeft = calculateTimeLeft(option.validUntil)
				const {days, hours, minutes} = timeLeft

				if (days === 0 && hours === 0 && minutes === 0) {
					expiredIndexes.push(index)
				} else if (
					days < earliestTime.days ||
                    (days === earliestTime.days && hours < earliestTime.hours) ||
                    (days === earliestTime.days && hours === earliestTime.hours && minutes < earliestTime.minutes)
				) {
					earliestTime = timeLeft
					earliestIndex = index
				}
			})

			setExpiredIndexes(expiredIndexes)
			setEarliestExpiringIndex(earliestIndex)
		}

		calculateExpirationInfo()
	}, [options])

	const spinWheel = () => {
		if (spinning || options.length === 0) {
			return
		}

		setSpinning(true)

		const validOptions = options.filter(option => {
			const {days, hours, minutes} = calculateTimeLeft(option.validUntil)
			return (days > 0 || hours > 0 || minutes > 0) && option.mainText !== "no-win-title"
		})

		const validIndices = options.map((option, index) => {
			const {days, hours, minutes} = calculateTimeLeft(option.validUntil)
			return (days > 0 || hours > 0 || minutes > 0) && option.mainText !== "no-win-title" ? index : null
		}).filter(index => index !== null)

		const randomIndex = Math.floor(Math.random() * validOptions.length)
		const selectedIndex = validIndices[randomIndex]

		const selected = validOptions[randomIndex] || validOptions[0]
		setSelectedOption(selected)

		const anglePerOption = 360 / options?.length
		const selectedRotation = selectedIndex * anglePerOption
		const randomSign = Math.random() < 0.5 ? -1 : 1
		const randomOffset = randomSign * (10 + (Math.random() * 10))

		const extraSpins = 360 * 16
		const finalRotation = -(selectedRotation + randomOffset + extraSpins)

		setRotation(finalRotation)

		const duration = 6100

		setTimeout(() => {
			setSpinning(false)
			setHighlightedIndex(selectedIndex)
			handleFormSubmission(selected ? selected.forms[locale] : "")

			setTimeout(() => setIsSpinComplete(true), 2400)
		}, duration)
	}

	const themeClass = isSpringWheel ? "spring" : isNewYearWheel ? "newyear" : isChristmasWheel ? "christmas" : "default"

	return (
		<>
			{isLoading ? <div className="p-5">
				<Spinner/>
			</div> :
				<>
					{showWheel ? (
						<WheelOfFortuneModal
							selectedOption={selectedOption}
							isSpinComplete={isSpinComplete}
							formId={userFormId ? userFormId : selectedOption ?
								selectedOption.forms[locale] : ""}
							modalContentType="wheel"
							onClose={onClose}
							isChristmasWheel={isChristmasWheel}
							isNewYearWheel={isNewYearWheel}
							isSpringWheel={isSpringWheel}
							theme={themeClass}
						>
							<div className={`${styles["wheel-container"]} ${styles[`theme-${themeClass}`]} `}>
								<div className={styles.arrow}>
									<Image src={isSpringWheel ? "/assets/icons/wheel-of-fortune-arrow-green.svg" : "/assets/icons/wheel-of-fortune-arrow.svg"}
										alt="arrow down"
										width={40}
										height={32} />
								</div>
								<div className={styles["border-circles"]}>
									{Array.from({length: 8}).map((_, index) => (
										<div key={index}
											className={styles.circle}></div>
									))}
								</div>
								<div className={styles["lights-wrapper"]}>
									{Array.from({length: 4}).map((_, index) => (
										<div key={index}
											className={styles.light}></div>
									))}
								</div>
								<fieldset
									className={styles["ui-wheel-of-fortune"]}
									style={{
										transform: `rotate(${rotation}deg)`,
										["--_items" as any]: options?.length
									}}>
									<WheelOptionsBorders optionsLength={options.length}
										theme={themeClass}
									/>
									<ul data-items={options.length}>
										{options.map((option, index) => {
											const {days, hours, minutes} = calculateTimeLeft(option.validUntil)
											let expiryText = ""

											if (days === 0 && hours === 0 && minutes === 0) {
												expiryText = t("expired")
											} else {
												expiryText = null
											}

											return (
												<li
													key={index}
													className={`${index === highlightedIndex ?
														styles["selected-option"] : ""} 
                                                    ${index === options.length - 1 && options.length % 2 !== 0 ?
													styles["last-odd"] : ""}
                                                    ${expiredIndexes.includes(index) ? styles.expired : ""}`}>
													<div className={styles["text-section"]}>
														<Typography variant={option.mainText === "no-win-title" ?
															"bodyLgBlack" : "heading1Black"}
														semanticTag="h3"
														className={styles["wheel-option-title"]}>
															{t(option.mainText)}
														</Typography>
														<Typography variant="bodySmBold"
															semanticTag="h3"
															className={styles["wheel-option-desc"]}
															style={{fontSize: 10}}>
															{t(option.secondaryText)}
														</Typography>
														{expiryText && (
															<Typography variant="bodySmBold"
																semanticTag="h3"
																className={`${styles["option-gone-text"]} text-center`}
																style={{fontSize: 10}}>
																{expiryText}
															</Typography>
														)}
													</div>
												</li>
											)
										})}
									</ul>
								</fieldset>
								<div className={styles["button-container"]}>
									<button type="button"
										disabled={spinning}></button>
								</div>
							</div>
						</WheelOfFortuneModal>
					) :
						userFormId ? (
							<WheelOfFortuneModal
								selectedOption={selectedOption ? selectedOption : selectedOptionFallback}
								isSpinComplete={true}
								formId={userFormId}
								modalContentType="form"
								onClose={onClose}
								isChristmasWheel={isChristmasWheel}
								isNewYearWheel={isNewYearWheel}
								isSpringWheel={isSpringWheel}
								theme={themeClass}
							/>
						) : showAfterSubmitModal && isOpenedFromStickyBar ?
							<AfterSubmitModalContent
								onClose={onClose}
							/> : null
					} </>

			}
		</>
	)
}

export default WheelOfFortune

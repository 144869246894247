import Image from "next/image"
import Link from "next/link"
import SearchDesktop from "../../search/SearchDesktop"
import WhatsAppButton from "../WhatsAppButton"
import CartButton from "../CartButton"
import React from "react"
import WishListButton from "../WishListButton"
import MyBikePlanAI from "../../reusable/NavBarAI"
import ComparisonButton from "../ComparisonButton"
import NavBarCtas from "./NavBarCtas"

type Props = {
  isFrontPage: boolean
	setIsBackgroundOn?: any
	isBackgroundOn?: boolean
}

const DesktopMidHeader: React.FC<Props> = ({isFrontPage, setIsBackgroundOn, isBackgroundOn}) => {
	return (
		<div className="d-flex justify-content-between align-items-center"
			style={{margin: "12px 0px"}}>
			<div className="col-3 d-flex align-items-center"
				style={{columnGap: 16, minHeight: 40}}>
				<Link href={"/"}
					as={"/"}>
					<a className="d-inline-flex align-items-center">
						<Image
							className="pointer main-logo"
							width={202}
							height={28}
							src="/assets/MyBikePlan.ch.svg"
							alt="MyBikePlan logo"
							style={{marginTop: 1}}
						/>
					</a>
				</Link>
			</div>
			{JSON.parse(process.env.NEXT_PUBLIC_AI_IS_ACTIVATED || "false") ?
				<div className="d-flex col-5 align-items-center justify-content-start"
					style={{gap: ".5rem"}}>
					<div className="col-10">
						<SearchDesktop setIsBackgroundOn={setIsBackgroundOn}
							isBackgroundOn={isBackgroundOn}/>
					</div>
					<MyBikePlanAI />
				</div> : <div className="col-4">
					<SearchDesktop setIsBackgroundOn={setIsBackgroundOn}
						isBackgroundOn={isBackgroundOn}/>
				</div>}
			<NavBarCtas isFrontPage={isFrontPage} />
		</div>
	)
}

export default DesktopMidHeader

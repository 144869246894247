export const getReviewsData = () => {
	const reviewsObj = reviewsData
	reviewsObj.reviews = reviewsObj.reviews.filter(review => review.comment.length >= 80)
	return reviewsObj
}

const reviewsData = {
	reviews: [
		{
			reviewId: "AbFvOqmK-2uJOfi0Q-XzQrwT9LAwHizF9aFarUTBR5qAeUpa6YMAb9bv8pivQdb1FiveshUBoTHGvw",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJyjSBnwRfmJ2P56sfDngAH90Tz4KIZTPAxva4nG=s120-c-c0x00000000-cc-rp-mo-ba4-br100",
				displayName: "Manuel Herzog"
			},
			starRating: "FIVE",
			comment: "Alles sehr einfach und unkompliziert. Das Fahrrad wurde schon 8 Tage nach Bestellung geliefert, obwohl es hiess, es hätte 4 Wochen Lieferzeit. Kann es jedem Empfehlen :)",
			createTime: "2024-04-21T08:54:45.624Z",
			updateTime: "2024-04-21T08:54:45.624Z",
			reviewReply: {
				comment: "Besten Dank für die Bewertung, das freut uns sehr! 🤩 🙏",
				updateTime: "2024-04-21T09:26:48.846Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqmK-2uJOfi0Q-XzQrwT9LAwHizF9aFarUTBR5qAeUpa6YMAb9bv8pivQdb1FiveshUBoTHGvw",
			lang: "de"
		},
		{
			reviewId: "AbFvOqmZdWhq5pauubbsz3u3ZMZ3dMyU9nS8dXQaRBaPb3tUcBm5PS-L2vlYspli3hzEo201K0TyUw",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJzbs2tdCNhY-jEB7DTnECp7gErTJeK7cvCsJ0tF=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Said Lulic"
			},
			starRating: "FIVE",
			comment: "Ich bin sehr zufrieden und würde es jedem weiterempfehlen...sie sind sehr professionell, halten die Lieferung und Lieferfristen ein...machen Sie einfach weiter so.",
			createTime: "2024-04-07T07:57:39.597Z",
			updateTime: "2024-04-07T07:57:39.597Z",
			reviewReply: {
				comment: "Vielen Dank für die Bewertung! Beste Grüsse und wir wünschen viel Spass mit dem E-Bike! :-)",
				updateTime: "2024-04-16T08:50:06.392Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqmZdWhq5pauubbsz3u3ZMZ3dMyU9nS8dXQaRBaPb3tUcBm5PS-L2vlYspli3hzEo201K0TyUw",
			lang: "de"
		},
		{
			reviewId: "AbFvOqnnwgPNpvhuAsZufrcqGT3hmi-bSAxfoLUlXICV-GxFolJfV9iNXgBf8cUHpicgjfN7Jgv3JQ",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJzGO-3fFvnYTc0pFjTwlvxcAuxmU1DUHdeOZtOF=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Saiful Meah"
			},
			starRating: "FIVE",
			comment: "Vom Cyberweek Top Angebot gesehen, Bestellt, Telefon kam von Firma, top Beratung sehr ausführlich, Lieferung reibungslos mit externen Firma klappte alles ohne Probleme, bei meinen offenen Fragen wurde ich sehr gut beraten, Elektro Velo bis jetzt Top super für die Stadt!!!",
			createTime: "2024-04-20T08:45:41.408Z",
			updateTime: "2024-04-20T08:45:41.408Z",
			reviewReply: {
				comment: "Vielen Dank für die Bewertung & viel Spass mit dem E-Bike! :-)",
				updateTime: "2024-04-20T07:06:45.105Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqnnwgPNpvhuAsZufrcqGT3hmi-bSAxfoLUlXICV-GxFolJfV9iNXgBf8cUHpicgjfN7Jgv3JQ",
			lang: "de"
		},
		{
			reviewId: "AbFvOqm-YRbJl5-0IEbRjgiKw4v2w-TPpkA9Z2ZM7a5DIsyCZa7edz_MlARPSuqJiq4Zr7Xg0dLr7w",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJyk_ytP6-0q3XIEHw_b-LLMMCHVwFRiHo8uAafF=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Silvan Steiger"
			},
			starRating: "FIVE",
			comment: "Heute Bike bestellt, sehr unkompliziert und einfach. Meine Fragen wurden vom Kundendienst umgehend beantwortet. Kann es Allen weiterempfehlen. Vielen Dank mybikeplan!",
			createTime: "2024-04-21T10:31:12.371Z",
			updateTime: "2024-04-21T10:31:12.371Z",
			reviewReply: {
				comment: "Vielen Dank - Das finden wir toll! 😎",
				updateTime: "2024-04-21T06:57:14.232Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqm-YRbJl5-0IEbRjgiKw4v2w-TPpkA9Z2ZM7a5DIsyCZa7edz_MlARPSuqJiq4Zr7Xg0dLr7w",
			lang: "de"
		},
		{
			reviewId: "AbFvOqlnt-NZTUEAtSRNkm_x1rAVPtBlogRxka1yCeR0vdiD0jo71fJE6XD7TLyOaJO-1dbkv3YVUA",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14GiLcT8lF5lOAVl1peG3fVSiPQsuQlERLu8M_R_MRg=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Anjuli Jäckle"
			},
			starRating: "FIVE",
			comment: "Andri hat uns super beraten. Wollten uns zuerst noch in anderen Geschäften umsehen, da die Beratung aber so toll war, haben wir gleich zwei eBikes gekauft. Können wir also nur empfehlen 👍",
			createTime: "2024-04-21T00:17:53.705Z",
			updateTime: "2024-04-21T00:17:53.705Z",
			reviewReply: {
				comment: "Vielen lieben Dank für die Bewertung! Wir freuen uns, das zu lesen und wünschen viel Spass! 😀",
				updateTime: "2024-04-21T06:56:27.694Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqlnt-NZTUEAtSRNkm_x1rAVPtBlogRxka1yCeR0vdiD0jo71fJE6XD7TLyOaJO-1dbkv3YVUA",
			lang: "de"
		},
		{
			reviewId: "AbFvOqmpEg8Peo8wZvS5x2ATtNTMo3rIJ1kQmPaT7Xr6BwSeZxwQFXlTByzWTKUDv4fDNqIJAbOo",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJwG-Gzn6t8eKTbMWlDGp6pmaKD0CyPM1zlADpOq=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "John Hagman"
			},
			starRating: "FIVE",
			comment: "Ein super Service vom A-Z. Telefonische Beratung kompetent und freundlich. Super schnelle Abwicklung und die Lieferung war auch zeitnah. Die Finanzierung schnell und unproblematisch. Kann MyBikePlan nur weiter empfehlen.",
			createTime: "2024-04-20T11:43:59.144Z",
			updateTime: "2024-04-20T11:43:59.144Z",
			reviewReply: {
				comment: "Vielen Dank für das positive Feedback! Wir freuen uns sehr darüber und wünschen viel Spass! :-)",
				updateTime: "2024-04-26T07:10:32.062Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqmpEg8Peo8wZvS5x2ATtNTMo3rIJ1kQmPaT7Xr6BwSeZxwQFXlTByzWTKUDv4fDNqIJAbOo",
			lang: "de"
		},
		{
			reviewId: "AbFvOqnYi4FwZK-kzjtD7nMdGg_2TzfbHhCo5JkfTETcxczQca4UhZ3aDz2crBbPYu1yAhoo0e20AQ",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14GgaXe-rP461_6y2mb7dE96eN67FBwRVq8cVfFTyUNg=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Bjoern Hentschel"
			},
			starRating: "FIVE",
			comment: "Kann einfach sagen Hammer was diese Firma anbiet. Habe das Fahrrad für meine erkrankte Partnerin gekauft und ein Anhänger für unsere 1jahr alten Tochter. Hammer Ratenzahlungs Plan. Echt zu empfehlen. Ging alles super schnell ohne Problem",
			createTime: "2024-04-19T18:40:39.660Z",
			updateTime: "2024-04-19T18:40:39.660Z",
			reviewReply: {
				comment: "Vielen lieben Dank für die Bewertung - wir freuen uns riesig darüber! :-) Wir wünschen Ihnen von Herzen viel Spass mit Ihrem E-Bike! 🚴😎",
				updateTime: "2024-04-22T09:08:22.945Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqnYi4FwZK-kzjtD7nMdGg_2TzfbHhCo5JkfTETcxczQca4UhZ3aDz2crBbPYu1yAhoo0e20AQ",
			lang: "de"
		},
		{
			reviewId: "AbFvOqnvpE0QlQ5_Ri-__HfJwItI193t17ICb4lKBm5UQd0zCt88v1nSA_EwCUdDfOLDJ147E_fdUg",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14GjaoXntNo2E7ikjrDYt2EhMVY_rbeCD0ZUNA5Gykmk=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Nil Karakus"
			},
			starRating: "FIVE",
			comment: "Ein tolles Team und es ging alles so schnell das mein E-Bike innerhalb einer Woche schon geliefert wurde habe schon MyBikePlan an 4 Personen erwähnt 2 sind voll begeistert und werden demnächst auch einen passenden E-Bike bestellen! Kann es nur weiter empfehlen :)",
			createTime: "2024-04-17T07:23:41.799Z",
			updateTime: "2024-04-17T07:23:41.799Z",
			reviewReply: {
				comment: "Vielen Dank für die Bewertung! Es freut uns sehr, dies zu lesen und wir wünschen Ihnen viel Spass mit dem E-Bike! :-)",
				updateTime: "2024-04-22T07:12:07.150Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqnvpE0QlQ5_Ri-__HfJwItI193t17ICb4lKBm5UQd0zCt88v1nSA_EwCUdDfOLDJ147E_fdUg",
			lang: "de"
		},
		{
			reviewId: "AbFvOqm7L98WqDAOs5_IbzGmTFQSl0zjcQCDssfRaeItue88zLtNV46RxE5bxzmX-0Tz-DwXo2e2",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJwZE6gagTWjuktar463wwCfaI_AydGaDe4Pov8Z=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "David Pacheco"
			},
			starRating: "FIVE",
			comment: "Je recommande vivement, avec moi tout se c'est très bien passé, j'ai reçu mon e-bike même avant le délai et je suis absolument un client satisfait!",
			createTime: "2021-07-16T10:17:07.062Z",
			updateTime: "2021-07-16T10:17:07.062Z",
			reviewReply: {
				comment: "Merci pour ce beau retour ! Nous te souhaitons plein de belles aventures sur la route ! :-)",
				updateTime: "2021-07-23T06:25:05.456Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqm7L98WqDAOs5_IbzGmTFQSl0zjcQCDssfRaeItue88zLtNV46RxE5bxzmX-0Tz-DwXo2e2",
			lang: "fr"
		},
		{
			reviewId: "AbFvOqmVHBV-B9RP9-4Dka7D-V1L303-ld7Bor9jfws8SKrW1uHloo8yK4xUaaoK07Dmm9GnWVa3WA",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJyOrLI2DvGq4dkgJUQmT0jBTY_-1Jm5Su0pkPD3=s120-c-c0x00000000-cc-rp-mo-ba3-br100",
				displayName: "Andri Spiller"
			},
			starRating: "THREE",
			comment: "War zunächst etwas skeptisch, hat aber alles super geklappt und heute düse ich durch die Gegend.\n\nUPDATE: So schnell wie man an sein eBike kommt, so dürftig ist der Service sobald man es hat. Deshalb nur noch drei Sterne.",
			createTime: "2021-07-14T09:22:05.174Z",
			updateTime: "2021-07-14T09:22:05.174Z",
			reviewReply: {
				comment: "Super, das freut uns! Viel Spass weiterhin! :-)",
				updateTime: "2021-04-29T07:14:12.271Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqmVHBV-B9RP9-4Dka7D-V1L303-ld7Bor9jfws8SKrW1uHloo8yK4xUaaoK07Dmm9GnWVa3WA",
			lang: "de"
		},
		{
			reviewId: "AbFvOqmRMzaxYd1KMHr6WTknZ-xO7GX82XmltorgLgZimdTSw-zA0cpR0s6jLzU_xAMHa7sOrkTU",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJznjm1eNynY_KUgI3FnWH_ja-1YyOOLfpgCS-iv=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Prune Texier"
			},
			starRating: "FIVE",
			comment: "Topissime !",
			createTime: "2021-07-01T10:01:44.051Z",
			updateTime: "2021-07-01T10:01:44.051Z",
			reviewReply: {
				comment: "Merci beaucoup pour ce joli retour ! :-)",
				updateTime: "2021-07-09T14:01:02.486Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqmRMzaxYd1KMHr6WTknZ-xO7GX82XmltorgLgZimdTSw-zA0cpR0s6jLzU_xAMHa7sOrkTU",
			lang: "fr"
		},
		{
			reviewId: "AbFvOqkggM-YNWLIDACRZ2tC4y8iuVe6_QQN0GH-cdT3aZ15rTVFF7XhjdjM3CwyYk9FxJbz62amAg",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14GhUgOXqOBVy-t_tF_kp3GPmRUMZgNYsNJIBEcs9RA=s120-c-c0x00000000-cc-rp-mo-ba3-br100",
				displayName: "Pires Carlos"
			},
			starRating: "FIVE",
			comment: "Excellente entreprise. Je suis vraiment content du service et prestations.",
			createTime: "2021-06-25T20:06:41.735Z",
			updateTime: "2021-06-25T20:06:41.735Z",
			reviewReply: {
				comment: "Nous sommes ravis de vous savoir satisfait ! Nous vous souhaitons de bons moments avec votre nouvel E-Bike! :-)",
				updateTime: "2021-06-28T07:37:16.108Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqkggM-YNWLIDACRZ2tC4y8iuVe6_QQN0GH-cdT3aZ15rTVFF7XhjdjM3CwyYk9FxJbz62amAg",
			lang: "fr"
		},
		{
			reviewId: "AbFvOqnklpyn6y4ihIalx_W9Oe6o545r-fjl69ubuWyiQ8Q-TsJYWKxhmGJr5LP4SwweIsa1YCJC",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14Gi5vZhEYS-sE_QMC9RwoNgRwyOSRCCBkhy-HGdB=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Lanquetin Yann"
			},
			starRating: "FIVE",
			comment: "Top 👍🏼",
			createTime: "2021-06-25T19:20:23.037Z",
			updateTime: "2021-06-25T19:20:23.037Z",
			reviewReply: {
				comment: "Merci pour votre message Yann. Nous sommes ravis de savoir que vous avez appréciez notre service et espèrons que vous passerez d'excellents moments sur la route avec votre E-Bike. :-)",
				updateTime: "2021-06-28T07:41:52.002Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqnklpyn6y4ihIalx_W9Oe6o545r-fjl69ubuWyiQ8Q-TsJYWKxhmGJr5LP4SwweIsa1YCJC",
			lang: "fr"
		},
		{
			reviewId: "AbFvOqkduosjivazyb-VJTGU5YpizQFMiP4ev1lpGfFB0gnxEmR7ymb68fZIVgYq5JV1rlBk2dI5TQ",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJzfj_JOpp89QCePOBBvhsd9awI0ByREaGTtsTgN=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Victor Da Silva"
			},
			starRating: "FOUR",
			comment: "Traitement très simple et réponse rapide. Processus efficace sur la commande et un délai de livraison assez court. La livraison s'effectue au pas de la porte, ce qui est appréciable.\n\nLe suivi client est efficace, je leur souhaite le meilleur pour la suite et plein de réussite.",
			createTime: "2021-06-24T11:45:36.833Z",
			updateTime: "2021-06-24T11:45:36.833Z",
			reviewReply: {
				comment: "Merci encore pour votre retour constructif Victor ! Nous vous souhaitons de très bons moments avec votre nouvel E-Bike! :-)",
				updateTime: "2021-06-28T07:36:58.351Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqkduosjivazyb-VJTGU5YpizQFMiP4ev1lpGfFB0gnxEmR7ymb68fZIVgYq5JV1rlBk2dI5TQ",
			lang: "fr"
		},
		{
			reviewId: "AbFvOqlDNY4rcvvXxVk93zDetAQQO4h5tfQ9OJTK1ZYfysyq0taxlvnkQmMKpdWUqo-v599k4MNAAg",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14GiuP25ZJb-zM5BT1AZZNGtuo_V-FSiDPgcRqXC_=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Nathalie Haab"
			},
			starRating: "FIVE",
			comment: "Un service clientèle très réactif et rapide; une commande exécutée très rapidement, la livraison promise sous 2 à 3 semaines est arrivée au bout de 7 jours, le vélo était prêt à rouler. Service 5 étoiles, je recommande!",
			createTime: "2021-06-23T06:08:40.463Z",
			updateTime: "2021-06-23T06:08:40.463Z",
			reviewReply: {
				comment: "Merci pour ce super commentaire Nathalie ! Nous vous souhaitons une belle continuation avec votre E-Bike! :-)",
				updateTime: "2021-06-28T07:36:37.479Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqlDNY4rcvvXxVk93zDetAQQO4h5tfQ9OJTK1ZYfysyq0taxlvnkQmMKpdWUqo-v599k4MNAAg",
			lang: "fr"
		},
		{
			reviewId: "AbFvOqnF6PE1dyymxJu6xqTimK3nxEXPhYWidfUPi06OOAziX3DH4t-ixdWR47FIFUWHBNpy3O3sDw",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJxKyMW96FPh0BkQhoYaq7XmDTHgqFxf0D6obEY0=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "julien Tarallo"
			},
			starRating: "FIVE",
			comment: "Voilà j ai bien reçu mon vélo un poil en avance super contact je recommande vivement",
			createTime: "2021-06-22T13:35:53.943Z",
			updateTime: "2021-06-22T13:35:53.943Z",
			reviewReply: {
				comment: "Merci pour votre retour Julien ! Nous vous souhaitons plein de bons moments avec votre nouvel E-Bike! :-)",
				updateTime: "2021-06-28T07:36:07.184Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqnF6PE1dyymxJu6xqTimK3nxEXPhYWidfUPi06OOAziX3DH4t-ixdWR47FIFUWHBNpy3O3sDw",
			lang: "fr"
		},
		{
			reviewId: "AbFvOqlxemutPIo-ySe4sytL3qwSyXvF7GlYXEpixbL-MMujtL1O46h7h_NIv6_zeRPXNTU3BS0p",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14GgdW1sFfCBBUIKv8FXawSK0Itt4S4kxa-KTHNimUA=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "paris oscar"
			},
			starRating: "FIVE",
			comment: "Merci à toute l'équipe. Un peux d'attente due à la forte demande mais un  très bon suivi des délais. Livraison impeccable avec un livreur super arrangeant. Et un vélo sans la moindre marque. Je recommande à 100%.",
			createTime: "2021-06-21T04:38:31.723Z",
			updateTime: "2021-06-21T04:38:31.723Z",
			reviewReply: {
				comment: "Merci pour ton avis ! Nous espèrons que tu passeras des moments 100% agréable avec ton nouvel E-Bike !  😊",
				updateTime: "2021-06-22T07:43:19.278Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqlxemutPIo-ySe4sytL3qwSyXvF7GlYXEpixbL-MMujtL1O46h7h_NIv6_zeRPXNTU3BS0p",
			lang: "fr"
		}
	],
	averageRating: 4.6,
	totalReviewCount: 400,
	nextPageToken: "ABHRLXUcOYw2x5VfukeNa8sMAouWbA5WWXb7ugRfDA6lMQd-Kinpm6Y"
}


import React, {useEffect, useState} from "react"
import GoogleRbox from "../../googleReviews/GoogleRbox"
import PropTypes from "prop-types"
import fetchReviews from "../../../fetch/FetchReviews"
import NavTooltipsSlider from "./NavTooltipsSlider"
import FinancingExplanationTooltip from "../../bikeDetails/components/FinancingExplanationTooltip"

const infoArray = [
	{
		text: "no-interest-bike-now-pay-later",
		tooltip: {
			icon: "/assets/icons/info-transparent.svg",
			tooltipComponent: <FinancingExplanationTooltip/>,
			activeIcon: "/assets/icons/info-active.svg"
		}
	},
	{
		text: "social-proof-label",
		icon: "/assets/icons/yellow-heart.svg",
		iconFirst: true
	},
	{
		text: "free-delivery",
		tooltip: {
			icon: "/assets/icons/info-transparent.svg",
			tooltipText: "free-service-shipping",
			activeIcon: "/assets/icons/info-active.svg"
		}
	},
	{
		text: "cancel-and-exchange-policy"
	},
	{
		text: "two-year-guarantie",
		tooltip: {
			icon: "/assets/icons/info-transparent.svg",
			tooltipText: "guarantee-and-service-stores",
			activeIcon: "/assets/icons/info-active.svg"
		}
	},
	{
		text: "service-partners-quantity"
	},
	{
		text: "creditcheck-eligible-for-financing-title",
		icon: "/assets/icons/chevron-dark-bg-light.svg",
		link: "/creditcheck/2"
	},
	{
		text: "get-contacted-card-title",
		icon: "/assets/icons/chevron-dark-bg-light.svg",
		isConsultationLink: true
	},
	{
		text: "custom-offer-card-title",
		icon: "/assets/icons/chevron-dark-bg-light.svg",
		link: "/suggestion/1"
	},
	{
		text: "sponsorship-title",
		icon: "/assets/icons/chevron-dark-bg-light.svg",
		link: "/blog/neu-lass-dir-dein-e-bike-sponsoren"
	},
	{
		icon: "/assets/icons/checkmark-emoji.svg",
		whiteIcon: "/assets/icons/usp-service-white.svg",
		text: "best-monthly-price-label",
		iconFirst: true,
		tooltip: {
			icon: "/assets/icons/info-transparent.svg",
			tooltipText: "best-monthly-price-description-label",
			activeIcon: "/assets/icons/info-active.svg"
		}
	}
]

function TopNavTooltips() {
	const [reviewArray, setReviewArray] = useState(null)

	useEffect(() => {
		setReviewArray(fetchReviews())
	}, [])

	return (
		<div className="bg-grey"
			style={{width: "100%", height: "2.2rem"}}>
			<div
				className="d-flex align-items-center justify-content-between"
				style={{
					maxWidth: 1890,
					margin: "0 auto",
					padding: "0 64px",
					zIndex: 999
				}}>
				<div
					className="row slider-tooltips position-relative align-items-center"
					style={{zIndex: 100000, width: 800, height: "2.5rem"}}>
					<NavTooltipsSlider slides={infoArray} />
				</div>
				{reviewArray ? (
					<div style={{marginTop: "-5px"}}>
						<GoogleRbox reviews={reviewArray} />
					</div>
				) : null}
			</div>
		</div>
	)
}

TopNavTooltips.propTypes = {
	reviews: PropTypes.array
}

export default TopNavTooltips
